import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRouter from "./components/privateRouter";
import { AuthProvider } from "./connections/authContext";
import Account from "./pages/access/account";
import Login from "./pages/access/login";
import Register from "./pages/access/register";
import Bookings from "./pages/cardSections/bookings";
import Certificates from "./pages/cardSections/certificates";
import Contact from "./pages/cardSections/contact";
import Experience from "./pages/cardSections/experience";
import Portfolio from "./pages/cardSections/portfolio";
import Profile from "./pages/cardSections/profile";
import Recomendations from "./pages/cardSections/recomendations";
import Resume from "./pages/cardSections/resume";
import Services from "./pages/cardSections/services";
import Courses from "./pages/extras/courses";
import Events from "./pages/extras/events";
import Jobs from "./pages/extras/jobs";
import MenuPage from "./pages/extras/menu";
import Social from "./pages/extras/social";
import Privacy from "./pages/legals/privacy";
import ForgotPassword from "./pages/access/forgotPassword";
import ChangePassword from "./pages/access/changePassword";
import Messages from "./pages/messages/messages";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Card Sections */}
          <Route path="/" element={<PrivateRouter><MenuPage /></PrivateRouter>} />
          <Route path="/profile" element={<PrivateRouter><Profile /></PrivateRouter>} />
          <Route path="/resume" element={<PrivateRouter><Resume /></PrivateRouter>} />
          <Route path="/certificates" element={<PrivateRouter><Certificates /></PrivateRouter>} />
          <Route path="/experience" element={<PrivateRouter><Experience /></PrivateRouter>} />
          <Route path="/portfolio" element={<PrivateRouter><Portfolio /></PrivateRouter>} />
          <Route path="/services" element={<PrivateRouter><Services /></PrivateRouter>} />
          <Route path="/recomandations" element={<PrivateRouter><Recomendations /></PrivateRouter>} />
          <Route path="/booking" element={<PrivateRouter><Bookings /></PrivateRouter>} />
          <Route path="/contact" element={<PrivateRouter><Contact /></PrivateRouter>} />

          {/* Extras */}
          <Route path="/messages" element={<PrivateRouter><Messages /></PrivateRouter>} />
          <Route path="/courses" element={<PrivateRouter><Courses /></PrivateRouter>} />
          <Route path="/jobs" element={<PrivateRouter><Jobs /></PrivateRouter>} />
          <Route path="/events" element={<PrivateRouter><Events /></PrivateRouter>} />
          <Route path="/social" element={<PrivateRouter><Social /></PrivateRouter>} />

          {/* Access */}
          <Route path="/account" element={<PrivateRouter><Account /></PrivateRouter>} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password">
            <Route path=":token" element={<ChangePassword />} />
          </Route>

          {/* Legal */}
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

import { useEffect, useState } from "react";
import { Icon } from "../../components/icons";
import { InputControl, TextareaControl } from "../../components/inputControls";
import Structure from "../structure/structure";

const Services = () => {
    const [ services, setServices ] = useState([]);
    
    useEffect(() => {
        setServices([
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon02.png", title:"Facial Limpieza Profunda", description:"Consiéntete y limpia tu piel a profundidad con las mejores técnicas y productos para dejar tu piel suave y renovada. Este tratamiento consiste en realizar una limpieza de la piel, tonificación, exfoliación para la eliminación de células muertas, extracción, aplicación de mascarilla personalizada según las necesidades."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon02.png", title:"Facial de Hidratación Profunda", description:"El facial de hidratación profunda es un tratamiento que proporciona la hidratación que tu rostro necesita, brinda a la piel seca o deshidratada mayor vitalidad. La piel luce tersa y brillante, ayuda a mejorar la absorción de nutrientes, dejando tu piel visiblemente rejuvenecida y con una mejor apariencia."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon01.png",title:"Hydrafacial",description:"Tratamiento facial con los mejores productos y tecnología que sirve para limpiar, extraer e hidratar la piel al tiempo que infunde sustancias altamente eficaces adaptadas a las necesidades específicas de tu piel. Este proceso ayuda a  mejora el aspecto de varios problemas de la piel como son las líneas de expresión, firmeza y textura, así como congestión de los poros. Es un tratamiento suave no invasivo y de efecto inmediato."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon01.png",title:"Depilación Láser",description:"Depilación por medio de aparatología con láser diodo atacando y eliminando el vello indeseado desde la raíz con una efectividad de hasta un 90%, siendo efectivo para cualquier tipo y tono de piel. No es doloroso ni invasivo, librándote de la irritación y cortadas causadas por otros métodos de depilación."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon01.png",title:"Maderoterapia",description:"Existe un antes y un después de probar la maderoterapia. Este tratamiento consiste en la realización de distintas maniobras de masaje con instrumentos de madera. lleva una presión fuerte y una velocidad rápida. Se trabaja en las zonas con mayor grasa del cuerpo provocando calor con movimientos especiales. Sirve para moldear y definir la silueta, así como mejorar la circulación, disminuir la celulitis y dar mayor tonicidad a la piel."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon01.png",title:"Masaje Relajante",description:"Relaja tu cuerpo y mente promoviendo la respiración profunda y eliminando el sentimiento de ansiedad. Reduce y elimina dolores musculares, así como contracturas y tensión acumulada. Favorece la eliminación de toxinas y células muertas gracias a un mayor flujo sanguíneo y linfático logrando una piel más sana, suave y relajada."},
            {price:"1500.00",time:"30",image:"https://getwelltalent.com/img/icon01.png",title:"Masaje Drenaje Linfático",description:"Masaje suave y ligero con un ritmo lento que se aplica sobre el sistema circulatorio y linfático cuyo objetivo es moviliza los líquidos del organismo para favorecer la eliminación de las sustancias de desecho del cuerpo. Entre sus beneficios se encuentran: reducir la inflamación, mejorar las defensas del organismo, producir un efecto sedante y de relajación, depuración de toxinas, entre otras."},
        ]);
    },[]);

    return(
        <Structure>
            <section id="services" className="card-page">
                <h1 className="title">Servicios</h1>
                {/*<h2 className="subtitle"></h2>*/}

                <div className="services-box">
                    <Service />

                    {services.map(service => {
                        return(
                            <SavedService
                                id={service.title}
                                image={service.image}
                                title={service.title}
                                description={service.description}
                                time={service.time}
                                price={service.price}
                            />
                        );
                    })}

                </div>
            </section>
        </Structure>
    );
};

export default Services;

const Service = () => {
    return(
        <div className="service">
            <div className="icons">
                <p>Selecciona un ícono para tu servicio</p>
                <div className="icons-selector">
                    <button><img src="https://getwelltalent.com/img/icon01.png" alt="" /></button>
                    <button><img src="https://getwelltalent.com/img/icon02.png" alt="" /></button>
                </div>
            </div>
            <div className="inputs">
                <InputControl
                    type="text"
                    leftIcon="title"
                    placeholder="Nombre del servicio"
                />
                <TextareaControl
                    leftIcon="view_headline"
                    placeholder="Breve descripción del servicio"
                    rows="5"
                />
                <InputControl
                    type="time"
                    leftIcon="timer"
                    placeholder="Horas del servicio"
                />
                <InputControl
                    type="number"
                    leftIcon="attach_money"
                    placeholder="Precio"
                />
            </div>
            <div className="buttons">
                <button className="btn"><Icon name="save" /> Guardar</button>
            </div>
        </div>
    );
};

const SavedService = ({image,title,description,time,price}) => {
    return(
        <div className="service">
            <div className="header">
                <div className="icon">
                    <img src={image} alt="" />
                </div>
                <h3>{title}</h3>
            </div>
            <div className="description">
                <p>{description}</p>
                <p><strong><Icon name="timer" /> {time} minutos</strong></p>
                <p><strong>$ {price} mxn</strong></p>
            </div>
            <div className="buttons">
                <button className="circle"><Icon name="delete" /></button>
            </div>
        </div>
    );
};
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../components/icons";
import { useAuth } from "../../connections/authContext";

const V1Structure = ({children}) => {
    const { logout } = useAuth();

    function handleLogout(e){
        e.preventDefault();

        logout();
    }
    return(
        <Fragment>
            <nav className="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <img src="./assets/img/wcLogo.png" width="20" />
                    </Link>

                    <Link className="navbar-item" to="/">
                        <Icon name="dashboard" />
                        <strong className="is-hidden-mobile">&nbsp; Tu Card</strong>
                    </Link>
                    {/*<Link className="navbar-item" to="/messages">
                        <Icon name="mail" />
                        <strong className="is-hidden-mobile">&nbsp; Mensajes</strong>
                    </Link>*/}
                    <Link className="navbar-item" to="/courses">
                        <Icon name="school" />
                        <strong className="is-hidden-mobile">&nbsp; Formación</strong>
                    </Link>
                    <Link className="navbar-item" to="/jobs">
                        <Icon name="work" />
                        <strong className="is-hidden-mobile">&nbsp; Empleos</strong>
                    </Link>
                    <Link className="navbar-item" to="/events">
                        <Icon name="event" />
                        <strong className="is-hidden-mobile">&nbsp; Eventos</strong>
                    </Link>
                    <Link className="navbar-item" to="/social">
                        <Icon name="quick_phrases" />
                        <strong className="is-hidden-mobile">&nbsp; WT Feed</strong>
                    </Link>

                    <button onClick={handleLogout} role="button" className="navbar-burger"><Icon name="logout" /></button>
                </div>
                <div className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <button onClick={handleLogout} className="button is-primary"><Icon name="logout" /></button>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="container">{children}</div>
        </Fragment>
    );
};

export default V1Structure;
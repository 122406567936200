import { TikTok } from "react-tiktok";

const EmbedVideo = ({url}) => {
    let platform = true;
    if(url.includes("tiktok")) platform = false;
    return(
        platform ?
        <YouTube url={url} /> :
        <Tiktok url={url} />
    );
};

export default EmbedVideo;

 const YouTube = ({url}) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    
    const youtubeId = (match && match[2].length === 11)
        ? match[2]
        : null;

    //console.log(youtubeId);
    const newUrl = `https://www.youtube.com/embed/${youtubeId}`;

    return(
        <iframe width="100%" height="315" src={newUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    );
 };

const Tiktok = ({url}) => {

    return(
        <TikTok url={url} />
    );
}
import { useEffect, useState } from "react";
import V1Structure from "../structure/v1Structure";
import { useAuth } from "../../connections/authContext";
import { useAPI } from "../../connections/useAPI";

const Jobs = () => {
    const [ jobs, setJobs ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { token } = useAuth();
    const { extras } = useAPI();

    useEffect(() => {
        extras.jobs(token).then(data => {
            setJobs(data);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    return(
        <V1Structure>
            <div className="block">
                <p className="box section-description">
                    Encuentra la mejor oferta de empleo para ti.
                </p>
            </div>
            <div className="block">
                {loading ? <p className="ml-5">Cargando datos...</p> : (jobs.length === 0) && <p className="ml-5">Próximamente... Más vacantes disponibles.</p>}
                {jobs.map(job => {
                    return(
                        <div key={job.extrasJobsId} className={`block card`}>
                            {job.express && <header className="card-header is-primary"><p className="card-header-title has-text-white">Urgente</p></header>}
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="image is-128x128">
                                        <img className="is-rounded" src={job.picture} alt="Placeholder image" />
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <p className="title is-4">{job.title}</p>
                                        <p className="subtitle is-6">{job.company}</p>
                                    </div>
                                </div>
                                <div className="content">
                                    {job.description}
                                </div>
                                <footer className="card-footer">
                                    <a href={job.link} target="_blank" className={`card-footer-item`}>{job.buttonName}</a>
                                </footer>
                            </div>
                        </div>
                    );
                })}
            </div>
        </V1Structure>
    );
};

export default Jobs;
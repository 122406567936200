import { ProfileResume } from "../../components/cardSections/profile/profileResume";
import { Icon } from "../../components/icons";
import { InputControl } from "../../components/inputControls";
import PhantomStructure from "../structure/phantom";

const Profile = () => {
    return(
        <PhantomStructure>
            <ProfileResume
                width="250px"
            />
            <hr />
            <div className="profile-socials">
                <InputControl
                    type="text"
                    placeholder="Elige una dirección única de wtCards"
                    leftIcon="qr_code"
                />
                
                <InputControl
                    type="text"
                    placeholder="Coloca tu enlace a Facebook"
                    leftIcon="fa-facebook-f"
                />
                <InputControl
                    type="text"
                    placeholder="Coloca tu enlace a Instagram"
                    leftIcon="fa-instagram"
                />
                <InputControl
                    type="text"
                    placeholder="Coloca tu enlace a LinkedIn"
                    leftIcon="fa-linkedin-in"
                />
            </div>
            <div className="profile-save" style={{textAlign:"right"}}>
                <button>Guardar Perfil</button>
            </div>
        </PhantomStructure>
    );
};

export default Profile;
import { useEffect, useState, Fragment } from "react";
import { useAuth } from "../../connections/authContext";
import { useAPI } from "../../connections/useAPI";
import { Icon } from "../icons";

const FeedBlock = ({id,name,userName,profilePicture,date,likes,bookmarks,replies,comment,picture,liked,saved,replied,openRepliesModal,listIndex,remove}) => {
    const [ localLikes, setLocalLikes ] = useState(likes);
    const [ localBookmarks, setLocalBookmarks ] = useState(bookmarks);
    const [ localLiked, setLocalLiked ] = useState(liked);
    const [ localSaved, setLocalSaved ] = useState(saved);
    const [ formattedComment, setFormattedComment ] = useState("");
    const [ likesButtonLoading, setLikesButtonLoading ] = useState(false);
    const [ savesButtonLoading, setSavesButtonLoading ] = useState(false);
    const [ removable, setRemovable ] = useState(false);
    const [ removeLoading, setRemoveLoading ] = useState(false);
    const { comments, profile } = useAPI();
    const { token } = useAuth();

    useEffect(() => {
        profile.get(token).then((response) => {
            if(response[0].link == userName)
                setRemovable(true);
        });

        setFormattedComment(formatComment());
    },[]);

    function changeLikes(){
        setLikesButtonLoading(true);

        if(localLiked == 0){
            comments.addLike(token, id).then(response => {
                if(response){
                    setLocalLikes(localLikes + 1);
                    setLocalLiked(1);
                }
            }).finally(() => {
                setLikesButtonLoading(false);
            });
        }else{
            comments.removeLike(token, id).then(response => {
                if(response){
                    setLocalLikes(localLikes - 1);
                    setLocalLiked(0);
                }
            }).finally(() => {
                setLikesButtonLoading(false);
            });
        }
    }

    function changeSaves(){
        setSavesButtonLoading(true);

        if(localBookmarks == 0){
            comments.addSave(token, id).then(response => {
                if(response){
                    setLocalBookmarks(localBookmarks + 1);
                    setLocalSaved(1);
                }
            }).finally(() => {
                setSavesButtonLoading(false);
            });
        }else{
            comments.removeSave(token, id).then(response => {
                if(response){
                    setLocalBookmarks(localBookmarks - 1);
                    setLocalSaved(0);
                }
            }).finally(() => {
                setSavesButtonLoading(false);
            });
        }
    }

    function formatComment(){
        const lines = comment.split("\n");
        return(lines.map((line,index) => {
            const regex = /(https?:\/\/[^\s]+)/g;
            const linesWithUrl = line.split(regex).map((parte, i) => {
                if (regex.test(parte)) {
                    return (
                    <a href={parte} target="_blank" key={i}>
                        {parte}
                    </a>
                    );
                } else {
                    // Si no es una URL, devuelve la parte original de la línea
                    return parte;
                }
            });
            return <Fragment key={index}>{linesWithUrl}<br /></Fragment>
        }));
    }

    function removeComment(){
        setRemoveLoading(true);

        if(window.confirm("Si le das click en aceptar, se eliminará tu comentario."))
            comments.remove(token, id).then((response) => {
                if(response)
                    remove(listIndex);
            }).finally(() => {
                setRemoveLoading(false);
            });
        else
            setRemoveLoading(false);
    }

    function replyComment(){
        if(openRepliesModal)
            openRepliesModal({id,name,userName,profilePicture,date,likes,bookmarks,comment,picture,liked,saved,replies,replied,listIndex,remove});
    }

    return(
        <div className="block">
            <div className="box">
                <article className="media">
                    <figure className="media-left">
                        <div className="profile-picture" style={{width:"60px",height:"60px"}}>
                            <img src={profilePicture} />
                        </div>
                    </figure>
                    <div className="media-content">
                        <div className="content">
                            <div>
                                <strong>{name}</strong>
                                <br /><a href={`https://wellt.cards/${userName}`} target="_blank"><small>@{userName}</small></a> <small>{date}</small>
                                <p className="mt-2">
                                    {formattedComment}
                                </p>
                                { picture && 
                                    <div className="feed-picture" style={{textAlign:"center"}}>
                                        <a href={picture} target="_blank">
                                            <img src={picture} style={{width: "100%",maxWidth:"350px"}} />
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <nav className="level is-mobile mt-4">
                            <div className="level-left">
                                <button className={`button level-item ${localLiked ? "is-primary" : "is-white"}`} onClick={changeLikes} disabled={likesButtonLoading}><Icon name="favorite" size="small" />&nbsp;{localLikes}</button>
                                <button className={`button level-item ${localSaved ? "is-primary" : "is-white"}`} onClick={changeSaves} disabled={savesButtonLoading}><Icon name="bookmark" size="small" />&nbsp;{localBookmarks}</button>
                                {(replies != undefined) ? <button className={`button level-item ${replied ? "is-primary" : "is-white"}`} onClick={replyComment}><Icon name="reply_all" size="small" />&nbsp;{replies}</button> : ""}
                            </div>
                        </nav>
                    </div>
                    {
                        (removable) &&
                        <div className="media-right">
                            <button className="button is-white" onClick={removeComment} disabled={removeLoading}><Icon name="delete" /></button>
                        </div>
                    }
                </article>
            </div>
        </div>
    );
};

export default FeedBlock;
import { useEffect, useRef, useState } from "react";
import { InputControl } from "../../inputControls";
import { useAPI } from "../../../connections/useAPI";
import { useAuth } from "../../../connections/authContext";

const Contact = () => {
    const { token } = useAuth();
    const [ email, setEmail ] = useState("");
    const [ emailData, setEmailData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const { contactMail } = useAPI();

    useEffect(() => {
        setLoading(true);

        contactMail.get(token).then((response) => {
            if(!response.error){
                setEmail(response[0].mail);
                setEmailData(response[0]);
            }
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function handleChange(e){
        setEmail(e.currentTarget.value);
    }

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);

        if(emailData.mail){
            contactMail.edit(token,emailData.id,email).then((response) => {
                if(!response.error)
                    setEmailData(response[0]);
            }).finally(() => {
                setLoading(false);
            });
        }else{
            contactMail.add(token, email).then((response) => {
                if(!response.error)
                    setEmailData(response[0]);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return(
        <div className="box mb-6">
            <h2 className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Contacto</h2>
            <p>Escribe un correo profesional para que puedan contactarte</p>
            <form onSubmit={handleSubmit}>
                <div className="block">
                    <InputControl type="email" placeholder="Correo Electrónico Profesional" leftIcon="mail" value={email} onChange={handleChange} required />
                </div>
                <div className="block">
                    <button type="submit" className="button is-primary" disabled={loading}>Guardar</button>
                </div>
            </form>
        </div>
    );
};
export default Contact;
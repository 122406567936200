import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Structure = ({children}) => {
    const [ menuActive, setMenuActive ] = useState(false);

    function toggleMenu(){
        const active = menuActive;
        setMenuActive(!active);
    }

    return(
        <div className="app">
            <div className="card">
                <div className="card-header">
                    <div className="logo-img-container">
                        <a href="/" target="_blank">
                            <img alt="" src="./assets/img/logo-dark.png" />
                        </a>
                    </div>
                    <div className="menu-btn-container">
                        <button className="circle" onClick={toggleMenu}><i className="material-symbols-rounded">menu</i></button>
                    </div>
                </div>
                <div className="card-container">
                    
                    {children}

                    {/*<button id="next-btn" className="circle">
                        <i className="material-symbols-rounded">arrow_forward_ios</i>
                    </button>*/}
                </div>
            </div>
            <div className={menuActive ? "menu active" : "menu"}>
                <div className="menu-container">
                    <div className="menu-content">
                        <div className="image-profile"><img alt="" src="./assets/img/erika1.png" /></div>
                        <h1 className="title">Erika Palomera Plascencia</h1>
                        <h4 className="subtitle">Licenciada en Nutrición<br />Cosmetóloga Certificada</h4>
                        <ul className="menu-btns">
                            <li><NavLink to="/" className="menu-link">Inicio</NavLink></li>
                            <li><NavLink to="/resume" className="menu-link">Resumen</NavLink></li>
                            <li><NavLink to="/portfolio" className="menu-link">Portafolio</NavLink></li>
                            <li><NavLink to="/services" className="menu-link">Servicios</NavLink></li>
                            <li><NavLink to="/recomandations" className="menu-link">Recomendaciones</NavLink></li>
                            <li><NavLink to="/booking" className="menu-link">Agenda</NavLink></li>
                            <li><NavLink to="/contact" className="menu-link">Contacto</NavLink></li>
                        </ul>
                    </div>

                    <div className="extra-menu">
                        <ul className="extra-menu-btns">
                            <li><Link to="/courses">Formación</Link></li>
                            <li><Link to="/jobs">Empleo</Link></li>
                            <li><Link to="/events">Eventos</Link></li>
                            <li><Link to="/tips">Tips</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="menu-header">
                    <div className="new-btn-container">
                        <button className="btn-white">Crea tu <img alt="" src="./assets/img/logo-dark.png" /></button>
                    </div>
                    <div className="close-btn-container">
                        <button className="circle" onClick={toggleMenu}>
                            <i className="material-symbols-rounded">close</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Structure;
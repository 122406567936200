import { Fragment, useEffect, useRef, useState } from "react";
import { Icon } from "../../icons";
import { InputControl, TextareaControl } from "../../inputControls";
import { useAuth } from "../../../connections/authContext";
import { useAPI } from "../../../connections/useAPI";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../../components/modals";
import { NotificationError, NotificationSuccess } from "../../notifications/notifications";
import EmbedVideo from "../../embedVideo";

const PresentationData = () => {
    const formVideoUrlRef = useRef();
    const formTextRef = useRef();
    const [ loading, setLoading ] = useState(false);

    const [ id, setId ] = useState(null);
    const [ videoModalActive, setVideoModalActive ] = useState(false);
    const [ textModalActive, setTextModalActive ] = useState(false);

    const [ videoUrl, setVideoUrl ] = useState("");
    const [ aboutText, setAboutText ] = useState("");

    const { token } = useAuth();
    const { portfolio } = useAPI();

    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");

    useEffect(() => {
        setLoading(true);
        portfolio.get(token).then((response) => {
            console.log(response);
            if(response.length > 0){
                setId(response[0].portfolioId);
                setVideoUrl(response[0].aboutVideo);
                setAboutText(response[0].aboutText);
            }
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function toggleVideoModal(){
        setVideoModalActive(!videoModalActive);
    }

    function handleVideoUrlChange(e){
        const value = e.currentTarget.value;
        setVideoUrl(value);
    }

    function handleVideoUrlSubmit(){
        const form = formVideoUrlRef.current;
        form.reportValidity();
        if(form.checkValidity()){
            setLoading(true);
            setError("");
            setSuccess("");

            if(!id){
                portfolio.add(token, aboutText, videoUrl).then((response) => {
                    if(response.error){
                        toggleVideoModal();
                        setError("Hubo un error al guardar los cambios.");
                    }else{
                        toggleVideoModal();
                        setId(response[0].portfolioId);
                        setVideoUrl(response[0].aboutVideo);
                        setSuccess("Se guardaron los cambios existosamente.");
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }else{
                portfolio.edit(token, id, aboutText, videoUrl).then((response) => {
                    if(response.error){
                        toggleVideoModal();
                        setError("Hubo un error al guardar los cambios.");
                    }else{
                        toggleVideoModal();
                        setSuccess("Se guardaron los cambios existosamente.");
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    function toggleTextModal(){
        setTextModalActive(!textModalActive);
    }

    function handleTextChange(e){
        const value = e.currentTarget.value;
        setAboutText(value);
    }

    function handleTextSubmit(){
        const form = formTextRef.current;
        form.reportValidity();
        if(form.checkValidity()){
            setLoading(true);
            setError("");
            setSuccess("");

            if(!id){
                portfolio.add(token, aboutText, videoUrl).then((response) => {
                    if(response.error){
                        toggleTextModal();
                        setError("Hubo un error al guardar los cambios.");
                    }else{
                        toggleTextModal();
                        setId(response[0].portfolioId);
                        setAboutText(response[0].aboutText);
                        setSuccess("Se guardaron los cambios existosamente.");
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }else{
                portfolio.edit(token, id, aboutText, videoUrl).then((response) => {
                    if(response.error){
                        toggleTextModal();
                        setError("Hubo un error al guardar los cambios.");
                    }else{
                        toggleTextModal();
                        setSuccess("Se guardaron los cambios existosamente.");
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    return(
        <Fragment>
            <div className="box">
                <h2 className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Presentación</h2>
                {loading ? <p>Cargando Datos</p> :
                <article className="media">
                    <div className="media-content has-text-centered">
                        {(videoUrl === "") ? 
                            <Fragment>
                                <img style={{width:"100%",maxWidth:"300px"}} src="https://img.freepik.com/free-vector/cameramen-taking-video-medical-expert-doctor-speaking-camera-cartoon-illustration_74855-14302.jpg" />
                            
                                <p>Cuéntanos en tu card más sobre ti profesionalmente. Puedes hacerlo en vídeo, simplemente en texto o ambos.</p>
                            </Fragment>
                            :
                            <EmbedVideo url={videoUrl} />
                        }
                        <p className="mt-6"><button className="button is-primary" onClick={toggleVideoModal}><Icon name="videocam" /> &nbsp;&nbsp; Hazlo en vídeo</button></p>
                        <hr />
                        { aboutText && <p className="mt-4">{aboutText}</p>}
                        <p className="mt-4"><button className="button is-primary" onClick={toggleTextModal}><Icon name="edit_note" /> &nbsp;&nbsp; Hazlo con texto</button></p>
                    </div>
                </article>}
            </div>

            <ModalCard active={videoModalActive} toggle={toggleVideoModal} title="Sube tu vídeo">
                <ModalCardContent>
                    <form ref={formVideoUrlRef} onSubmit={handleVideoUrlSubmit}>
                        <div className="content is-small">
                            <strong>Considera estos puntos:</strong>
                            <ul>
                                <li>Me presento...</li>
                                <li>Lo que más me gusta de mi profesión...</li>
                                <li>Lo que espero y busco en un nuevo sitio de trabajo...</li>
                                <li>Mis técnicas preferidas son...</li>
                                <li>Mis cualidades son...</li>
                            </ul>
                        </div>
                        <hr />
                        <InputControl
                            placeholder="Sube tu video a Youtube o TikTok y pega la url aquí"
                            value={videoUrl}
                            onChange={handleVideoUrlChange}
                            required
                        />
                    </form>
                </ModalCardContent>
                <ModalCardFooter>
                    <button type="submit" onClick={handleVideoUrlSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                    <button type="reset" onClick={toggleVideoModal} className="button is-secondary">Cancelar</button>
                </ModalCardFooter>
                
            </ModalCard>

            <ModalCard active={textModalActive} toggle={toggleTextModal} title="Sobre Mí">
                <ModalCardContent>
                    <form ref={formTextRef} onSubmit={handleTextSubmit}>
                        <div className="content is-small">
                            <strong>Considera estos puntos:</strong>
                            <ul>
                                <li>Me presento...</li>
                                <li>Lo que más me gusta de mi profesión...</li>
                                <li>Lo que espero y busco en un nuevo sitio de trabajo...</li>
                                <li>Mis técnicas preferidas son...</li>
                                <li>Mis cualidades son...</li>
                            </ul>
                        </div>
                        <hr />
                        <TextareaControl
                            placeholder="Explíca en pocas palabras por qué eres la mejor opción"
                            value={aboutText}
                            onChange={handleTextChange}
                            maxLength="500"
                            required
                        />
                    </form>
                </ModalCardContent>
                <ModalCardFooter>
                    <button type="submit" onClick={handleTextSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                    <button type="reset" onClick={toggleTextModal} className="button is-secondary">Cancelar</button>
                </ModalCardFooter>
            </ModalCard>

            {error && <NotificationError message={error} visibilityDuration={3000} />}
            {success && <NotificationSuccess message={success} visibilityDuration={3000} />}
        </Fragment>
    );
};

export default PresentationData;
import { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../connections/authContext";
import { useAPI } from "../../../connections/useAPI";
import { Icon } from "../../icons";
import { InputControl, InputFileControl, TextareaControl } from "../../inputControls";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../modals";

const EducationData = () => {
    const [ loading, setLoading ] = useState(true);
    const [ sendDataLoading, setSendLoading ] = useState(false);
    const [ modalActive, setModalActive ] = useState(false);
    const [ data, setData ] = useState([]);
    const defaultData = {school:"",startDate:"",endDate:"",description:"",file:''};
    const [ newData, setNewData ] = useState(defaultData);
    const { token } = useAuth();
    const { jobTraining } = useAPI();

    useEffect(() => {
        setLoading(true);

        jobTraining.get(token).then(response => {
            const serverData = [];

            response.forEach(item => {dataFormat(serverData,item)});
    
            setData(serverData);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function dataFormat(array, item){
        array.push({
            id: item.jobTrainingId,
            school: item.school,
            startDate: item.startDate,
            endDate: item.endDate,
            description: item.description,
            file: item.certificate
        });
    }

    function toggleModal(){
        const active = !modalActive;
        setModalActive(active);
    }

    function handleSubmit(e){
        e.preventDefault();

        setSendLoading(true);

        jobTraining.add(
            token,
            newData.school,
            newData.startDate,
            newData.endDate,
            newData.description,
            newData.file
        ).then((response) => {
            if(response.error){

            }else{
                const serverData = [];
                response.forEach(item => {dataFormat(serverData,item)});
                setData(serverData);

                setNewData(defaultData);
                setModalActive(false);
            }
        }).finally(() => {
            setSendLoading(false);
        });
    }

    function handleRemove(id){
        setSendLoading(true);

        jobTraining.delete(token,id).then((response) => {
            if(response.error){

            }else{
                const serverData = [];
                response.forEach(item => {dataFormat(serverData,item)});
                setData(serverData);
            }
        }).finally(() => {
            setSendLoading(false);
        });
    }

    return(
        <Fragment>
            <div className="box">
                <h2 className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Formación Profesional</h2>
                {loading ? <p>Cargando Datos</p> :
                <article className="media">
                    <div className="media-content">
                        {data.map((item) => {
                            return <EducationInfo
                                key={item.id}
                                data={item}
                                remove={handleRemove}
                            />;
                        })}
                    </div>
                    <div className="media-right">
                        <button className="button is-white" onClick={toggleModal}><Icon name="add" /></button>
                    </div>
                </article>}
            </div>

            <EducationModal
                active={modalActive}
                toggle={toggleModal}
                value={newData}
                setValue={setNewData}
                onSubmit={handleSubmit}
                loading={sendDataLoading}
            />
        </Fragment>
    );
};

export default EducationData;

const EducationInfo = ({data, remove}) => {
    const {id, school, startDate, endDate, description, file} = data;
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    function openDocument(){
        window.open(file);
    }

    function handleRemove(){
        remove(id);
    }

    return(
        <div className="media box">
            <div className="media-content">
                <h3 className="subtitle">{school}
                <br />{sDate.getFullYear()} - {eDate.getFullYear()}</h3>
                <p>{description}</p>
                {file && <button className="button is-light" onClick={openDocument}><Icon name="card_membership" /> Documento</button>}
            </div>
            <div className="media-right">
                <button className="button is-white" onClick={handleRemove}><Icon name="delete" /></button>
            </div>
        </div>
    );
};

const EducationModal = ({active, toggle, value, setValue, onSubmit, loading}) => {
    const formRef = useRef();
    const currentDate = new Date().toISOString().split('T')[0];

    function handleChange(e){
        const input = e.currentTarget;
        const field = input.getAttribute("field");
        const newValue = JSON.parse(JSON.stringify(value));

        if(field !== "file"){
            const inputValue = input.value;
            newValue[field] = inputValue;
        }else
            newValue[field] = input.files[0];

        setValue(newValue);
    }

    function handleSubmit(e){
        e.preventDefault();

        const form = formRef.current;
        form.reportValidity();
        if(form.checkValidity())
            onSubmit(e);
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Agregar Formación Profesional">
            <ModalCardContent>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <InputControl
                        type="text"
                        leftIcon="school"
                        label="Nombre de la institución"
                        placeholder="Escuela"
                        field="school"
                        value={value.school}
                        onChange={handleChange}
                        required
                    />
                    <div className="columns">
                        <div className="column">
                            <InputControl
                                type="date"
                                leftIcon="today"
                                label="Fecha de inicio"
                                max={currentDate}
                                field="startDate"
                                value={value.startDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="date"
                                leftIcon="event"
                                label="Fecha de término"
                                max={currentDate}
                                field="endDate"
                                value={value.endDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <InputControl
                        type="text"
                        leftIcon="workspace_premium"
                        label="Nombre del curso/certificado/diplomado"
                        field="description"
                        value={value.description}
                        onChange={handleChange}
                        required
                    />
                    <InputFileControl
                        type="file"
                        leftIcon="card_membership"
                        label="Sube tu título/cerfificado/diplomado"
                        field="file"
                        onChange={handleChange}
                        required
                    />
                </form>
            </ModalCardContent>
            <ModalCardFooter>
                <button type="submit" onClick={handleSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                <button type="reset" onClick={toggle} className="button is-secondary">Cancelar</button>
            </ModalCardFooter>
            
        </ModalCard>
    );
};
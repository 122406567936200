import { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../connections/authContext";
import { useAPI } from "../../../connections/useAPI";
import { Icon } from "../../icons";
import { InputControl, InputFileControl, TextareaControl } from "../../inputControls";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../modals";

const JobData = () => {
    const [ loading, setLoading ] = useState(true);
    const [ sendDataLoading, setSendLoading ] = useState(false);
    const [ modalActive, setModalActive ] = useState(false);
    const [ data, setData ] = useState([]);
    const defaultData = {id: '',company: '',startDate: '',endDate: '',position: '',description: '',file: ''};
    const [ newData, setNewData ] = useState(defaultData);
    const { token } = useAuth();
    const { workExperience } = useAPI();

    useEffect(() => {
        workExperience.get(token).then((response) => {
            const serverData = [];
            response.forEach(item => {responseFormat(serverData, item)});
            setData(serverData);
        }).finally(() => {
            setLoading(false);
        });
    
    },[]);

    function responseFormat(array, item){
        array.push({
            id: item.workExperienceId,
            company: item.company,
            startDate: item.startDate,
            endDate: item.endDate,
            position: item.position,
            description: item.description,
            file: item.recomendationLetter
        });
    }

    function toggleModal(){
        const active = !modalActive;
        setModalActive(active);
    }

    function handleSubmit(e){
        e.preventDefault();
        setSendLoading(true);

        workExperience.add(token,newData.company,newData.startDate,newData.endDate,newData.position,newData.description,newData.file).then((response) => {
            if(response.error){

            }else{
                const serverData = [];
                response.forEach(item => {responseFormat(serverData, item)});
                setData(serverData);
                setNewData(defaultData);
                setModalActive(false);
            }
        }).finally(() => {
            setSendLoading(false);
        });
    }

    function handleRemove(id){
        setSendLoading(true);

        workExperience.delete(token, id).then(response => {
            if(response.error){

            }else{
                const serverData = [];
                response.forEach(item => {responseFormat(serverData, item)});
                setData(serverData);
            }
        }).finally(() => {
            setSendLoading(false);
        });
    }

    return(
        <Fragment>
            <div className="box">
                <h2 className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Experiencia Laboral</h2>
                {loading ? <p>Cargando Datos</p> :
                <article className="media">
                    <div className="media-content">
                        {data.map((item) => {
                            return <JobInfo
                                key={item.id}
                                data={item}
                                remove={handleRemove}
                            />;
                        })}
                    </div>
                    <div className="media-right">
                        <button className="button is-white" onClick={toggleModal}><Icon name="add" /></button>
                    </div>
                </article>}
            </div>

            <JobModal
                active={modalActive}
                toggle={toggleModal}
                value={newData}
                setValue={setNewData}
                onSubmit={handleSubmit}
                loading={sendDataLoading}
            />
        </Fragment>
    );
};

export default JobData;

const JobInfo = ({data, remove}) => {
    const {id, company, startDate, endDate, position, description, file} = data;
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    function openDocument(){
        window.open(file);
    }

    function handleRemove(){
        remove(id);
    }

    return(
        <div className="media box">
            <div className="media-content">
                <h3 className="subtitle">{company}
                <br />{sDate.getFullYear()} - {eDate.getFullYear()}</h3>
                <p>{position}</p>
                <p>{description}</p>
                {file && <button className="button is-light" onClick={openDocument}><Icon name="card_membership" /> Carta</button>}
            </div>
            <div className="media-right">
                <button className="button is-white" onClick={handleRemove}><Icon name="delete" /></button>
            </div>
        </div>
    );
};

const JobModal = ({active, toggle, value, setValue, onSubmit, loading}) => {
    const formRef = useRef();
    const currentDate = new Date().toISOString().split('T')[0];

    function handleChange(e){
        const input = e.currentTarget;
        const field = input.getAttribute("field");
        const newValue = JSON.parse(JSON.stringify(value));

        if(field !== "file"){
            const inputValue = input.value;
            newValue[field] = inputValue;
        }else
            newValue[field] = input.files[0];

        setValue(newValue);
    }

    function handleSubmit(e){
        e.preventDefault();

        const form = formRef.current;
        form.reportValidity();
        if(form.checkValidity())
            onSubmit(e);
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Agregar Experiencia Laboral">
            <ModalCardContent>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <InputControl
                        type="text"
                        leftIcon="apartment"
                        label="Nombre de la Empresa"
                        placeholder="Empresa"
                        field="company"
                        value={value.company}
                        onChange={handleChange}
                        required
                    />
                    <div className="columns">
                        <div className="column">
                            <InputControl
                                type="date"
                                leftIcon="today"
                                label="Fecha de inicio"
                                max={currentDate}
                                field="startDate"
                                value={value.startDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="date"
                                leftIcon="event"
                                label="Fecha de término"
                                max={currentDate}
                                field="endDate"
                                value={value.endDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <InputControl
                        type="text"
                        leftIcon="workspace_premium"
                        label="Puesto"
                        placeholder="Nombre del Puesto"
                        field="position"
                        value={value.position}
                        onChange={handleChange}
                        required
                    />
                    <InputControl
                        type="text"
                        leftIcon="workspace_premium"
                        label="Breve descripción del trabajo"
                        placeholder="¿Qué hacías?"
                        field="description"
                        value={value.description}
                        onChange={handleChange}
                        required
                    />
                    <InputFileControl
                        type="file"
                        leftIcon="card_membership"
                        label="Sube una carta recomendación de la empresa hacia ti"
                        field="file"
                        onChange={handleChange}
                    />
                </form>
            </ModalCardContent>
            <ModalCardFooter>
                <button type="submit" onClick={handleSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                <button type="reset" onClick={toggle} className="button is-secondary">Cancelar</button>
            </ModalCardFooter>
        </ModalCard>
    );
};
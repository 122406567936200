import { Link } from "react-router-dom";
import V1Structure from "../structure/v1Structure";
import { ProfileFlash, ProfileResume } from "../../components/cardSections/profile/profileResume";
import { PersonalData } from "../../components/cardSections/resume/personalData2";
import { SocialLinks } from "../../components/cardSections/profile/socialLinks";
import EducationData from "../../components/cardSections/education/educationData";
import JobData from "../../components/cardSections/job/jobData";
import PortfolioData from "../../components/cardSections/portfolio/portfolioData";
import Contact from "../../components/cardSections/contact/contact";
import PresentationData from "../../components/cardSections/portfolio/presentationData";

const MenuPage = () => {
    return(
        <V1Structure>
            <div className="block">
                <ProfileResume />
            </div>
            <div className="block">
                <SocialLinks />
            </div>
            <div className="block">
                <PersonalData />
            </div>
            <div className="block">
                <JobData />
            </div>
            <div className="block">
                <EducationData />
            </div>
            <div className="block">
                <PresentationData />
            </div>
            <div className="block">
                <Contact />
            </div>
        </V1Structure>
    );
};

export default MenuPage;
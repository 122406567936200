import { Fragment, useEffect, useRef, useState } from "react";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../modals";
import { Feed } from "../../pages/extras/social";
import { Icon } from "../icons";
import { useAPI } from "../../connections/useAPI";
import { useAuth } from "../../connections/authContext";
import FeedBlock from "./feedBlock";

const RepliesModal = ({active, toggle, comment}) => {
    const [ loading, setLoading ] = useState(true);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ charactersLenght, setCharactersLength ] = useState(0);
    const [ replies, setReplies ] = useState([]);
    const replyTextRef = useRef();
    const { comments } = useAPI();
    const { token } = useAuth();

    useEffect(() => {
        setLoading(true);
        setReplies([]);

        if(comment){
            comments.getReplies(token, comment.id).then((response) => {
                if(response){
                    setReplies(response);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    },[comment]);

    function charactersCounter(){
        const text = replyTextRef.current.value;
        setCharactersLength(text.length);
    }

    function handleSubmit(e){
        e.preventDefault();

        setFormLoading(true);
        const text = replyTextRef.current.value;
        comments.add(token, text, null, comment.id).then((response) => {
            setReplies(response);
            replyTextRef.current.value = '';
        }).finally(() => {
            setFormLoading(false);
        });
    }

    function handleReplyRemove(index){
        const newReplies = JSON.parse(JSON.stringify(replies));
        newReplies.splice(index,1);

        setReplies(newReplies);
    }

    function handleFeedRemove(){
        comment.remove(comment.listIndex);
        toggle();
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Respuestas de Comentario">
            <ModalCardContent>
                {loading ? "Cargando Datos..." :
                    <Fragment>
                        {comment &&
                            <FeedBlock
                                key={comment.id}
                                id={comment.id}
                                name={comment.name}
                                userName={comment.userName}
                                profilePicture={comment.profilePicture}
                                date={comment.date}
                                likes={comment.likes}
                                bookmarks={comment.bookmarks}
                                comment={comment.comment}
                                picture={comment.picture}
                                liked={comment.liked}
                                saved={comment.saved}
                                replies={comment.replies}
                                replied={comment.replied}
                                listIndex="0"
                                remove={handleFeedRemove}
                            />
                        }

                        <div className="columns is-mobile">
                            <div className="column is-11 is-offset-1">
                                {replies.map((reply, replyIndex) => {
                                    return <FeedBlock
                                        key={reply.id}
                                        id={reply.id}
                                        name={reply.userName}
                                        userName={reply.link}
                                        profilePicture={reply.profilePicture}
                                        date={reply.date}
                                        likes={reply.likes}
                                        bookmarks={reply.saves}
                                        comment={reply.content}
                                        liked={reply.liked}
                                        saved={reply.saved}
                                        listIndex={replyIndex}
                                        remove={handleReplyRemove}
                                    />
                                })}
                            </div>
                        </div>
                    </Fragment>
                }
            </ModalCardContent>
            <ModalCardFooter>
                <form style={{width:"100%"}} onSubmit={handleSubmit}>
                    <div className="field has-addons" style={{width:"100%"}}>
                        <div className="control is-expanded">
                            <textarea ref={replyTextRef} className="input is-fullwidth" maxLength="240" onChange={charactersCounter} placeholder="Tu respuesta" required></textarea>
                        </div>
                        <div className="control">
                            <button type="submit" className="button is-primary" disabled={formLoading}>
                                <Icon name="send" />
                            </button>
                        </div>
                    </div>
                    <p className="ml-1">{charactersLenght}/240</p>
                </form>
            </ModalCardFooter>
        </ModalCard>
    );
};

export default RepliesModal;
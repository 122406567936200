import { forwardRef, Fragment, useEffect, useState } from "react";
import { Icon } from "./icons";

export const InputControl = forwardRef(({size, label, leftIcon, rightIcon, value, onChange, ...props}, ref) => {
    const [ localValue, setLocalValue ] = useState('');
    let pClasses = "control";
    if(leftIcon) pClasses += " has-icons-left";
    if(rightIcon) pClasses += " has-icons-right";

    function iconSelector(icon){
        if(icon){
            if(icon.includes("fa-"))
                return(<i className={icon}></i>);
            else
                return(<i className="material-symbols-rounded">{icon}</i>);
        }else
            return false;
    }

    function handleChange(e){
        const newValue = e.currentTarget.value;
        setLocalValue(newValue);
    }

    return(
        <div className="field">
            {label && <label className="label">{label}</label>}
            <div className={pClasses}>
                <input ref={ref} className={`input ${size}`} value={value ? value : localValue} onChange={onChange ? onChange : handleChange} {...props} />
                {leftIcon &&
                    <span className="icon is-small is-left">
                        {iconSelector(leftIcon)}
                    </span>
                }
                {rightIcon &&
                    <span className="icon is-small is-right">
                        {iconSelector(rightIcon)}
                    </span>
                }
            </div>
        </div>
    );
});

export const InputFileControl = forwardRef(({size, label, leftIcon, rightIcon, ...props}, ref) => {
    const [ localValue, setLocalValue ] = useState('');
    let pClasses = "control";
    if(leftIcon) pClasses += " has-icons-left";
    if(rightIcon) pClasses += " has-icons-right";

    function iconSelector(icon){
        if(icon){
            if(icon.includes("fa-"))
                return(<i className={icon}></i>);
            else
                return(<i className="material-symbols-rounded">{icon}</i>);
        }else
            return false;
    }

    return(
        <div className="field">
            {label && <label className="label">{label}</label>}
            <div className={pClasses}>
                <input ref={ref} className={`input ${size}`} {...props} />
                {leftIcon &&
                    <span className="icon is-small is-left">
                        {iconSelector(leftIcon)}
                    </span>
                }
                {rightIcon &&
                    <span className="icon is-small is-right">
                        {iconSelector(rightIcon)}
                    </span>
                }
            </div>
        </div>
    );
});

export const TextareaControl = forwardRef(({size, label, leftIcon, rightIcon, value, ...props}, ref) => {
    let pClasses = "control";
    if(leftIcon) pClasses += " has-icons-left";
    if(rightIcon) pClasses += " has-icons-right";

    function iconSelector(icon){
        if(icon){
            if(icon.includes("fa-"))
                return(<i className={icon}></i>);
            else
                return(<i className="material-symbols-rounded">{icon}</i>);
        }else
            return false;
    }

    return(
        <div className="field">
            {label && <label className="label">{label}</label>}
            <div className={pClasses}>
                <textarea ref={ref} className={`textarea ${size}`} value={value ? value : ''} {...props}></textarea>
                {leftIcon &&
                    <span className="icon is-small is-left">
                        {iconSelector(leftIcon)}
                    </span>
                }
                {rightIcon &&
                    <span className="icon is-small is-right">
                        {iconSelector(rightIcon)}
                    </span>
                }
            </div>
        </div>
    );
});

export const SelectControl = forwardRef(({size, label, icon, placeholder, options, value, ...props}, ref) => {
    return(
        <div className="field">
            { label && <label className="label">{label}</label>}
            <div className="control has-icons-left">
                <div className={`select ${size && size}`}>
                    <select value={value ? value : ''} {...props}>
                        {placeholder && <option value="">{placeholder}</option>}
                        {options && options.map((option, optionIndex) => {
                            return(
                                <option key={option.value} value={option.value}>{option.label}</option>
                            );
                        })}
                    </select>
                </div>
                {icon && <span className={`icon is-left ${size && size}`}>
                    <Icon name={icon} />
                </span>}
            </div>
        </div>
    );
});

export const InputSwitch = forwardRef(({label, leftText, rightText, onChange, name, value, ...props}, ref) => {
    const [ internalChecked, setInternalChecked ] = useState(value === leftText ? false : true);

    function handleChange(e){
        e.preventDefault();

        const checkedAux = internalChecked;
        setInternalChecked(!checkedAux);
        onChange({currentTarget:{
            value:checkedAux ? leftText : rightText,
            name
        }});
    }

    return(
        <div className="field" onClick={handleChange}>
            <label className="label">{label}</label>
            {leftText && <label className="mr-2">{leftText}</label>}
            <input ref={ref} type="checkbox" className="switch" {...props} checked={internalChecked} onChange={onChange} />
            <label>{rightText && rightText}</label>
        </div>
    );
});

export const MultipleInputs = forwardRef(({size, label, leftIcon, rightIcon, value, onChange, ...props}, ref) => {
    const [ values, setValues ] = useState([]);
    let pClasses = "control";
    if(leftIcon) pClasses += " has-icons-left";
    if(rightIcon) pClasses += " has-icons-right";

    useEffect(() => {
        console.log(values);
    },[values]);

    function iconSelector(icon){
        if(icon){
            if(icon.includes("fa-"))
                return(<i className={icon}></i>);
            else
                return(<i className="material-symbols-rounded">{icon}</i>);
        }else
            return false;
    }

    /*function handleAdd(e){
        e.preventDefault();

        const inputValues = values;
        inputValues.push()
        if(ref){

        }
    }*/

    return(
        <div className="field">
            {label && <label className="label">{label}</label>}
            {/*<div className="selected-items mb-1">
                <button className="button is-small is-light">Light</button>
            </div>*/}
            <div className={pClasses}>
                <input ref={ref} className={`input ${size}`} {...props} placeholder="Separado por comas" />
                {leftIcon &&
                    <span className="icon is-small is-left">
                        {iconSelector(leftIcon)}
                    </span>
                }
                {rightIcon &&
                    <span className="icon is-small is-right">
                        {iconSelector(rightIcon)}
                    </span>
                }
            </div>
            {/*<button type="button" className="button is-info is-small mt-1" onClick={handleAdd}>Agregar</button>*/}
        </div>
    );
});

export const MultipleSelect = forwardRef(({size, label, leftIcon, value, onChange, placeholder, options, ...props}, ref) => {
    const [ internalValue, setInternalValue ] = useState([]);

    return(
        <div className="field">
            {label && <label className="label">{label}</label>}
            <div className="selected-items mb-1">
                <button className="button is-small is-primary">Light</button>
            </div>
            <div className="control has-icons-left">
                <div className="select is-fullwidth">
                    <select ref={ref} {...props}>
                        {placeholder && <option value="">{placeholder}</option>}
                        {options && options.map(item => {
                            return(
                                <option key={item.id} value={item.value}>{item.label}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="icon is-small is-left">
                    <i className="fas fa-globe"></i>
                </div>
            </div>
        </div>
    );
});

export const AutoGeneratedInput = forwardRef(({type, ...props}, ref) => {
    let inputs = [];
    inputs["textarea"] = <TextareaControl {...props} ref={ref} />;
    inputs["multipleinputs"] = <MultipleInputs {...props} ref={ref} />;
    inputs["textandlevel"] = <TextAndLevelInput {...props} ref={ref} />;

    return(inputs[type] ? inputs[type] : <InputControl type={type} {...props} ref={ref} />);
});

export const TextAndLevelInput = forwardRef(({label,placeholder,options, value, setValue}, ref) => {

    function handleChange(e){
        const target = e.currentTarget;
        const type = target.getAttribute("type");
        const newValue = target.value;
        const name = (type === "text") ? "text" : "level";

        const newObject = JSON.parse(JSON.stringify(value));
        newObject[name] = newValue;

        setValue(newObject);
    }

    return (
        <div className="columns">
            <div className="column">
                <InputControl type="text" label={label} placeholder={placeholder} value={value ? value.text : ''} onChange={handleChange} />
            </div>
            <div className="column">
                <div className="field">
                    <label className="label">Nivel de {label}</label>
                    <div className="control">
                        <input
                            type="range"
                            min="0"
                            max={options.length-1}
                            value={value ? value.level : 0}
                            onChange={handleChange}
                        />
                        <br />
                        <span>{options[value ? value.level : 0]}</span>
                    </div>
                </div>
            </div>
        </div>
    );
});
import Structure from "../structure/structure";

const Portfolio = () => {
    return(
        <Structure>
            <section id="portfolio" className="card-page">
                <h1 className="title">Sobre Mí</h1>
                <p className="about">
                    <textarea rows="10">
                        Soy Erika Palomera, profesionista del bienestar, nutrióloga y cosmetóloga siempre
                        inspirada por el gusto de la belleza; apasionada y dedicada a mi profesión, a lo
                        largo de los años he adquirido las habilidades y conocimientos necesarios para
                        ofrecer los mejores tratamientos para la belleza integral.
                    </textarea>
                </p>
                <h1 className="title">Portafolio</h1>
                <div className="portfolio-box">

                    <div className="portfolio-item">
                        <img alt="" src="https://www.howtogeek.com/wp-content/uploads/2021/08/YouTube-logo-hero-1.png" />
                        <h3>Nombre del vídeo</h3>
                    </div>

                    <div className="portfolio-item">
                        <img alt="" src="https://www.howtogeek.com/wp-content/uploads/2021/08/YouTube-logo-hero-1.png" />
                        <h3>Nombre del vídeo</h3>
                    </div>

                    <div className="portfolio-item">
                        <img alt="" src="https://www.howtogeek.com/wp-content/uploads/2021/08/YouTube-logo-hero-1.png" />
                        <h3>Nombre del vídeo</h3>
                    </div>

                    <div className="portfolio-item">
                        <img alt="" src="https://www.howtogeek.com/wp-content/uploads/2021/08/YouTube-logo-hero-1.png" />
                        <h3>Nombre del vídeo</h3>
                    </div>

                </div>
            </section>
        </Structure>
    );
};

export default Portfolio;
import { useEffect, useState } from "react";
import { Icon } from "../../components/icons";

const Calendar = () => {
    return(
        <div className="calendar">
            <CalendarMonths />
            <div className="days-hours">
                <CalendarDays from={6} to={28} selected={6} />
                <div className="hours">
                    <CalendarHour hour="09:00" />
                    <CalendarHour hour="01:00" active />
                    <CalendarHour hour="03:00" />
                </div>
            </div>
        </div>
    );
};

export default Calendar;

const CalendarMonths = () => {
    const months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    return(
        <div className="months">
            <button><Icon name="chevron_left" /></button>
            <h4>Febrero - 2023</h4>
            <button><Icon name="chevron_right" /></button>
        </div>
    );
};

const CalendarDays = ({from, to, selected}) => {
    const [days, setDays] = useState([]);
    
    useEffect(() => {
        const arrayDays = [];
        for(let i = from;i <= to;i++){
            let number = i;
            if(number < 10) number = `0${i}`;
            arrayDays.push(number);
        }

        setDays(arrayDays);
    },[from,to]);

    return(
        <div className="days">
            {days.map(number => {
                let className = "day-button";
                if(parseInt(number) === parseInt(selected)) className += " active";
                return(<button key={number} className={className}>{number}</button>);
            })}
        </div>
    );
};

const CalendarHour = ({hour,active}) => {
    let className = "hour";
    if(active) className += " active";

    return(
        <div className={className}>
            <div className="hour-title">
                <h6 className="hour-person">Nombre de la Persona</h6>
                <h6 className="hour-hour">{hour} hrs</h6>
            </div>
            <div className="hour-description">
                <ul>
                    <li>Servicio 1</li>
                    <li>Servicio 2</li>
                    <li>Servicio 3</li>
                </ul>
            </div>
        </div>
    )
};
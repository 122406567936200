import V1Structure from "../structure/v1Structure";

const Privacy = () => {
    return(
        <V1Structure>
            <h1 className="title">Políticas de Privacidad</h1>
            <p>Bienvenido a WellTalent Cards. En esta política de privacidad, describimos cómo recopilamos, usamos y compartimos información sobre usted cuando utiliza nuestra aplicación web WellTalent Cards.</p>
            
            <h2>Recopilación de Información:</h2>
            <p>Recopilamos información sobre usted cuando utiliza WellTalent Cards. La información que recopilamos puede incluir su nombre, correo electrónico, foto de perfil y otra información que usted decida compartir con nosotros a través de la aplicación.</p>
            
            <h2>Uso de la Información:</h2>
            <p>Utilizamos la información que recopilamos para brindarle los servicios de WellTalent Cards. Esto incluye personalizar su experiencia en la aplicación, permitirle conectarse con otros usuarios y enviarle información relevante sobre la aplicación y sus funciones. También podemos utilizar la información para mejorar la calidad de nuestros servicios y desarrollar nuevas funcionalidades.</p>

            <h2>Compartir la Información:</h2>
            <p>Compartimos información con terceros que prestan servicios en nuestro nombre, como proveedores de servicios de alojamiento, análisis de datos y otros servicios similares. Estos terceros solo pueden utilizar la información para prestar servicios en nuestro nombre y están obligados a proteger la información de acuerdo con esta política de privacidad.</p>
            <p>También podemos compartir información con las autoridades competentes cuando sea necesario para cumplir con la ley, una orden judicial o una solicitud de un organismo gubernamental.</p>

            <h2>Seguridad de la Información:</h2>
            <p>Tomamos medidas razonables para proteger la información que recopilamos y mantenemos en nuestros servidores. Sin embargo, no podemos garantizar la seguridad de la información transmitida a través de Internet.</p>

            <h2>Opciones del Usuario:</h2>
            <p>Usted tiene la opción de acceder, actualizar y eliminar la información que ha proporcionado a través de la aplicación. También puede optar por no recibir comunicaciones de nuestra parte en cualquier momento.</p>

            <h2>Política de Cookies:</h2>
            <p>Utilizamos cookies y tecnologías similares para mejorar su experiencia en la aplicación y para recopilar información sobre cómo utiliza la aplicación. Puede configurar su navegador para rechazar las cookies, pero esto puede limitar su capacidad para utilizar todas las funcionalidades de la aplicación.</p>

            <h2>Cambios en la Política de Privacidad:</h2>
            <p>Podemos actualizar esta política de privacidad de vez en cuando. Si realizamos cambios significativos en la forma en que tratamos su información personal, le notificaremos a través de la aplicación o por correo electrónico.</p>
            
            <h2>Contacto:</h2>
            <p>Si tiene alguna pregunta sobre esta política de privacidad, puede comunicarse con nosotros a través de la aplicación o enviando un correo electrónico a nuestro equipo de soporte.</p>
            <p>Esta política de privacidad se rige por las leyes de los Estados Unidos Mexicanos y cualquier disputa relacionada con la misma se resolverá exclusivamente en los tribunales de los Estados Unidos Mexicanos.</p>
        </V1Structure>
    );
};

export default Privacy;
import { useEffect, useState } from "react";
import { Icon } from "../../components/icons";
import V1Structure from "../structure/v1Structure";
import { useAPI } from "../../connections/useAPI";
import { useAuth } from "../../connections/authContext";

const Events = () => {
    const [ events, setEvents ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { token } = useAuth();
    const { extras } = useAPI();

    useEffect(() => {
        extras.events(token).then(data => {
            setEvents(data);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    return(
        <V1Structure>
            <div className="block">
                <div className="box">
                    <p>Conoce y participa en los mejores eventos del sector.</p>
                </div>
            </div>

            <div className="columns is-multiline">
                {loading ? <p className="ml-5">Cargando datos...</p> : (events.length === 0) && <p className="ml-5">No hay eventos disponibles</p>}
                {events.map(event => {
                    return(
                        <Event
                            key={event.extrasEventsId}
                            id={event.extrasEventsId}
                            picture={event.picture}
                            title={event.title}
                            date={event.date}
                            description={event.description}
                            link={event.link}
                            buttonName={event.buttonName}
                            likes={event.likes}
                        />
                    );
                })}
            </div>
        </V1Structure>
    );
};

export default Events;

const Event = ({id,picture,title,date,description,likes,link,buttonName}) => {
    const formattedDate = new Date(`${date}T00:00:00`);
    const months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

    return(
        <div className="column">
            <div className="card">
                <div className="card-image">
                    <figure className="image">
                        <img src={picture} alt="Placeholder image" />
                    </figure>
                </div>
                <div className="card-content">
                    <article className="media">
                        <figure className="media-left has-text-centered">
                            <p><strong>{formattedDate.getDate()}</strong></p>
                            <p><small>{months[formattedDate.getMonth()]}</small></p>
                        </figure>
                        <div className="media-content">
                            <div className="content">
                                <p>
                                    <strong>{title}</strong>
                                    <br />
                                    {description}
                                </p>
                            </div>
                        </div>
                        <div className="media-right">
                            <a className="button is-primary is-fullwidth" href={link} target="_blank" title={buttonName}>
                                <Icon name="how_to_reg" size="small" />
                            </a>
                            {/*<br />
                            <button className={(!likes || likes < 1) ? "button is-white is-fullwidth" : "button is-white is-fullwidth has-text-info"}>
                                <Icon name="favorite" size="small" />&nbsp;<small>{likes ? likes : 0}</small>
                            </button>*/}
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};
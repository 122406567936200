import Calendar from "../bookings/calendar";
import Structure from "../structure/structure";

const Bookings = () => {
    return(
        <Structure>
            <section id="bookings" className="card-page">
                <h1 className="title">Agenda</h1>

                <Calendar />
            </section>
        </Structure>
    );
};

export default Bookings;
import { Link } from "react-router-dom";
import { InputControl } from "../../components/inputControls";
import PhantomStructure from "../structure/phantom";

const Resume = () => {
    return(
        <PhantomStructure>
            <ul className="data">
                <li>
                    <small>Nombre</small>
                    Erika Palomera Plascencia
                </li>
                <li>
                    <small>Fecha de Nacimiento</small>
                    <InputControl
                        type="date"
                        leftIcon="event"
                    />
                </li>
                <li>
                    <small>Instituto/Escuela</small>
                    <InputControl
                        type="text"
                        placeholder="Instituto/Escuela"
                        leftIcon="school"
                    />
                </li>
                <li>
                    <small>Idiomas</small>
                    <InputControl
                        type="text"
                        placeholder="Idioma"
                        leftIcon="language"
                    />
                    <InputControl
                        type="number"
                        min="0"
                        placeholder="Porcentaje"
                        leftIcon="percent"
                    />
                </li>
                <li>
                    <small>Nacionalidad</small>
                    <InputControl
                        type="text"
                        placeholder="Nacionalidad"
                        leftIcon="explore"
                    />
                </li>
                <li>
                    <small>Residencia Actual</small>
                    <InputControl
                        type="text"
                        placeholder="Residencia Actual"
                        leftIcon="location_on"
                    />
                </li>
            </ul>
            <div className="certificates">
                <Link to="/certificates">Certificaciones</Link>
            </div>
            <div className="experience">
                <Link to="/experience">Experiencia Laboral</Link>
            </div>

        </PhantomStructure>
    );
};

export default Resume;
import PhantomStructure from "../structure/phantom";

const Certificates = () => {
    return(
        <PhantomStructure>
            <ul className="experience">
                <li>
                    <strong>2021 - 2022</strong>
                    Experiencia en tratamientos faciales y corporales, tanto manuales como con
                    aparatología
                </li>
                <li>
                    <strong>2021 - 2022</strong>
                    Cosmetóloga y Terapeuta con Máster en Spa. Egresada de Agueda Academia
                    Spa
                </li>
                <li>
                    <strong>2015 - 2022</strong>
                    7 años de experiencia en asesoría nutricional para cambio de hábitos, así como
                    mejoramiento de ciertos padecimientos
                </li>
                <li>
                    <strong>2011 - 2015</strong>
                    Licenciada en Nutrición. Egresada del Centro Universitario de Ciencias de la
                    Salud de la Universidad de Guadalajara
                </li>
            </ul>
        </PhantomStructure>
    );
};

export default Certificates;
import "./access.css";
import { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputControl } from "../../components/inputControls";
import { useAPI } from "../../connections/useAPI";

const ChangePassword = () => {
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState("");
    const [ error, setError ] = useState("");
    const passwordRef = useRef();
    const { token } = useParams();
    const { auth } = useAPI();

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");

        auth.resetPassword(token,passwordRef.current.value).then((data) => {
            if(data.error){
                const errors = [];
                errors[401] = "Es probable que el token haya caducado.";
                errors[500] = "Hubo un error al restablecer las contraseñas.";

                setError(errors[data.error.code]);
            }else{
                setSuccess("Tu contraseña se ha restablecido correctamente.");
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return(
        <div className="hero is-fullheight is-primary access-background" style={{"marginTop":"-3.25rem"}}>
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="column is-8 is-offset-2">
                        <h3 className="title">Olvidé mi contraseña</h3>
                        <hr className="login-hr" />
                        <p className="subtitle">Te enviaremos un correo electrónico para restablecer tu contraseña</p>

                        <div className="box">
                            <div>
                                <img src="../assets/img/logo-dark.png" style={{"width":"200px"}} alt="WellTalent Cards Logo" />
                            </div>
                            { error ? <div className="notification is-danger">{error}</div> :
                                success ? <div className="notification is-success">{success}</div> :
                            <form onSubmit={handleSubmit}>
                                <InputControl ref={passwordRef} leftIcon="lock" type="password" placeholder="Nueva Contraseña" autoFocus required />

                                <button disabled={loading} className="button is-block is-primary is-large is-fullwidth">Cambiar Contraseña</button>
                            </form> }
                        </div>
                    </div>

                    <p className="has-text-white">
                        <Link to="/login">Ya recordé mi contraseña</Link>
                    </p>
                    <hr />
                    <p className="has-text-white">
                        <Link to="/policy">Políticas de Privacidad</Link> &nbsp;·&nbsp;
                        <Link to="/terms">Términos y Condiciones</Link>
                    </p>
                    
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
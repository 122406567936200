import { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../connections/authContext";
import { useAPI } from "../../../connections/useAPI";
import { Icon } from "../../icons";
import { InputControl } from "../../inputControls";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../modals";
import { NotificationError, NotificationSuccess } from "../../notifications/notifications";

export const SocialLinks = () => {
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ activeModal, setActiveModal ] = useState(false);
    const [ socialLinksList, setSocialLinksList ] = useState({});
    const [ newSocialLinks, setNewSocialLinks ] = useState({});
    const { socialLinks } = useAPI();
    const { token } = useAuth();

    useEffect(() => {
        setLoading(true);

        socialLinks.get(token).then((data) => {
            if(data.length > 0){
                setSocialLinksList(data[0]);
                setNewSocialLinks(data[0]);
            }
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function toggleModal(){
        const active = !activeModal;
        setActiveModal(active);
    }
    
    function handleSubmit(e){
        e.preventDefault();

        setFormLoading(true);
        setError("");
        setSuccess("");

        let f1 = null;
        if(newSocialLinks.facebook && newSocialLinks.facebook != '') f1 = newSocialLinks.facebook;
        let f2 = null;
        if(newSocialLinks.instagram && newSocialLinks.instagram != '') f2 = newSocialLinks.instagram;
        let f3 = null;
        if(newSocialLinks.linkedIn && newSocialLinks.linkedIn != '') f3 = newSocialLinks.linkedIn;

        if(socialLinksList.id){

            socialLinks.edit(token,socialLinksList.id,f1,f2,f3).then(data => {
                if(data.error){
                    setError("Hubo un error al guardar los datos, intenta de nuevo.");
                }else{
                    setSocialLinksList(data[0]);
                    setSuccess("Los datos se han guardado satisfactoriamente.");
                    toggleModal();
                }
            }).finally(() => {
                setFormLoading(false);
            });

        }else{

            socialLinks.add(token,f1,f2,f3).then(data => {
                if(data.error){
                    setError("Hubo un error al guardar los datos, intenta de nuevo.");
                }else{
                    setSocialLinksList(data[0]);
                    setSuccess("Los datos se han guardado satisfactoriamente.");
                    toggleModal();
                }
            }).finally(() => {
                setFormLoading(false);
            });

        }
    }

    return(
        <Fragment>
            <div className="box">
                {loading ? <p>Cargando datos...</p> :
                <article className="media">
                    <div className="media-content">
                        <p className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Comparte tus redes sociales</p>
                        <p className="has-text-centered">
                        {socialLinksList.facebook && <a className="button is-primary" href={`https://facebook.com/${socialLinksList.facebook}`} target="_blank"><Icon name="fab fa-facebook-f" /></a>}
                        &nbsp;
                        {socialLinksList.instagram && <a className="button is-primary" href={`https://instagram.com/${socialLinksList.instagram}`} target="_blank"><Icon name="fab fa-instagram" /></a>}
                        &nbsp;
    {socialLinksList.linkedIn && <a className="button is-primary" href={`https://linkedin.com/in/${socialLinksList.linkedIn}`} target="_blank"><Icon name="fab fa-linkedin-in" /></a>}
                        </p>
                    </div>
                    <div className="media-right">
                        <button className="button is-white" onClick={toggleModal}><Icon name="edit" /></button>
                    </div>
                </article>
                }
            </div>
            {!loading &&
            <SocialLinksModal
                active={activeModal}
                toggle={toggleModal}
                value={newSocialLinks}
                setValue={setNewSocialLinks}
                onSubmit={handleSubmit}
                loading={formLoading}
            />
            }

            {error && <NotificationError message={error} />}
            {success && <NotificationSuccess message={success} />}
        </Fragment>
    );
};

const SocialLinksModal = ({active, toggle, value, setValue, onSubmit, loading}) => {
    const formRef = useRef();

    function handleChange(e){
        e.preventDefault();

        const input = e.currentTarget;
        const field = input.getAttribute("field");
        const newValue = JSON.parse(JSON.stringify(value));
        newValue[field] = input.value;

        setValue(newValue);
    }

    function handleSubmit(e){
        e.preventDefault();

        const form = formRef.current;
        form.reportValidity();
        if(form.checkValidity())
            onSubmit(e);
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Redes Sociales">
                <ModalCardContent>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <InputControl
                            leftIcon="fab fa-facebook-f"
                            type="text"
                            label="Tu usuario de facebook"
                            placeholder="ej. @usuario"
                            field="facebook"
                            value={value.facebook ? value.facebook : ''}
                            onChange={handleChange}
                        />
                        <InputControl
                            leftIcon="fab fa-instagram"
                            type="text"
                            label="Tu usuario de instagram"
                            placeholder="ej. @usuario"
                            field="instagram"
                            value={value.instagram ? value.instagram : ''}
                            onChange={handleChange}
                        />
                        <InputControl
                            leftIcon="fab fa-linkedin-in"
                            type="text"
                            label="Tu usuario de LinkedIn"
                            placeholder="ej. @usuario"
                            field="linkedIn"
                            value={value.linkedIn ? value.linkedIn : ''}
                            onChange={handleChange}
                        />
                    </form>
                </ModalCardContent>
                <ModalCardFooter>
                    <button onClick={handleSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                    <button onClick={toggle} className="button is-secondary" disabled={loading}>Cancelar</button>
                </ModalCardFooter>
        </ModalCard>
    );
};
import { InputControl } from "../../components/inputControls";
import Structure from "../structure/structure";

const Contact = () => {
    return(
        <Structure>
            <section id="contact" className="card-page">
                <h1 className="title">Contacto</h1>

                {/*<form>
                    <input type="email" placeholder="Tu Correo Electrónico" />
                    <input type="text" placeholder="Tu nombre" />
                    <textarea placeholder="¿En qué te puedo ayudar?"></textarea>
                    <button className="btn">Enviar</button>
                </form>*/}
                
                <ul className="data">
                    <li>
                        <small>Correo Electrónico</small>
                        <InputControl
                            type="email"
                            placeholder="Tu correo electrónico"
                            leftIcon="mail"
                        />
                    </li>
                    <li>
                        <small>Teléfono</small>
                        <InputControl
                            type="text"
                            placeholder="Tú teléfono"
                            leftIcon="phone_android"
                        />
                    </li>
                </ul>
            </section>
        </Structure>
    );
};

export default Contact;
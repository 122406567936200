const BasicModal = ({children,active}) => {
    return(
        <div className={active ? `modal is-active` : `modal`}>
            <div className="modal-background"></div>
            {children}
        </div>
    );
};
export const ModalCard = ({title,children,active, toggle}) => {
    return(
        <BasicModal active={active}>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button className="delete" aria-label="close" onClick={toggle}></button>
                </header>
                {children}
            </div>
        </BasicModal>
    );
}
export const ModalCardContent = ({children}) => {
    return(
        <section className="modal-card-body">
            {children}
        </section>
    );
};
export const ModalCardFooter = ({children}) => {
    return(
        <footer className="modal-card-foot">
            {children}
        </footer>
    );
};
import { Fragment, useEffect, useRef, useState } from "react";
import { Icon } from "../../components/icons";
import { InputFileControl, TextareaControl } from "../../components/inputControls";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../components/modals";
import { useAuth } from "../../connections/authContext";
import { useAPI } from "../../connections/useAPI";
import V1Structure from "../structure/v1Structure";
import RepliesModal from "../../components/wtFeed/repliesModal";
import FeedBlock from "../../components/wtFeed/feedBlock";

const Social = () => {
    const [ feeds, setFeeds ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ modalActive, setModalActive ] = useState(false);
    const [ repliesModalActive, setRepliesModalActive ] = useState(false);
    const [ replyComment, setReplyComment ] = useState(null);
    const { comments } = useAPI();
    const { token } = useAuth();

    useEffect(() => {
        setLoading(true);

        comments.get(token).then((data) => {
            setFeeds(data);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function toggleModal(){
        const active = modalActive;
        setModalActive(!active);
    }

    function handleSubmit(comment, picture){
        setFormLoading(true);

        comments.add(token, comment, picture).then((data) => {
            if(data){
                setFeeds(data);
                setModalActive(false);
            }
        }).finally(() => {
            setFormLoading(false);
        });
    }

    function openRepliesModal(comment){
        setReplyComment(comment);
        setRepliesModalActive(true);
    }

    function closeRepliesModal(){
        setRepliesModalActive(false);
    }

    function handleFeedRemove(index){
        const newFeeds = JSON.parse(JSON.stringify(feeds));
        newFeeds.splice(index,1);

        setFeeds(newFeeds);
    }

    return(
        <V1Structure>
            <div className="block">
                <div className="box">
                    <button className="button is-primary is-fullwidth" onClick={toggleModal}><Icon name="reply" />&nbsp;Comparte un Comentario</button>
                </div>
            </div>
            {loading && <p className="ml-5">Cargando Datos...</p>}
            {feeds.map((feed, feedIndex) => {
                return(
                    <FeedBlock
                        key={feed.id}
                        id={feed.id}
                        name={feed.userName}
                        userName={feed.link}
                        profilePicture={feed.profilePicture}
                        date={feed.date}
                        likes={feed.likes}
                        bookmarks={feed.saves}
                        comment={feed.content}
                        picture={feed.picture}
                        liked={feed.liked}
                        saved={feed.saved}
                        replies={feed.replies}
                        replied={feed.replied}
                        openRepliesModal={openRepliesModal}
                        listIndex={feedIndex}
                        remove={handleFeedRemove}
                    />
                );
            })}

            <NewCommentModal
                active={modalActive}
                toggle={toggleModal}
                onSubmit={handleSubmit}
                loading={formLoading}
            />

            <RepliesModal
                active={repliesModalActive}
                toggle={closeRepliesModal}
                comment={replyComment}
            />
        </V1Structure>
    );
};

export default Social;

const UrlCard = ({urlData,url}) => {
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ image, setImage ] = useState("");

    useEffect(() => {
        setTitle(urlData.title);
        setDescription(urlData.description);
        setImage(urlData.image);
    },[]);

    return(
        <a href={url} target="_blank">
            <div className="card">
                <div className="card-image">
                    <figure className="image is-4by3">
                        <img src={image} alt="Placeholder image" />
                    </figure>
                </div>
                <div className="card-content">
                    <div className="content">
                        <p className="title is-4">{title}</p>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </a>
    );
};

const NewCommentModal = ({active, toggle, loading, onSubmit}) => {
    const formRef = useRef();
    const [ text, setText ] = useState('');

    useEffect(() => {
        if(!active)
            formRef.current.reset();
    },[active]);

    function handleChange(e){
        setText(e.currentTarget.value);
    }

    function handleSubmit(e){
        e.preventDefault();

        const form = formRef.current;
        form.reportValidity();
        if(form.checkValidity()){
            const formData = new FormData(form);
            const comment = formData.get("comment");
            let picture = null;
            if(formData.get("picture").name != '') picture = formData.get("picture");
            onSubmit(comment, picture);
        }
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Comparte un comentario">
            <ModalCardContent>
                <form ref={formRef}>
                    <TextareaControl
                        placeholder="Comentario - hasta 240 caracteres"
                        name="comment"
                        value={text}
                        max="240"
                        onChange={handleChange}
                        required
                    />
                    <InputFileControl leftIcon="image" type="file" name="picture" label="Sube una Imagen" />
                </form>
            </ModalCardContent>
            <ModalCardFooter>
                <button type="submit" onClick={handleSubmit} className="button is-primary" disabled={loading}>Enviar Comentario</button>
                <button type="reset" onClick={toggle} className="button is-secondary">Cancelar</button>
            </ModalCardFooter>
        </ModalCard>
    );
};
import { useEffect, useState } from "react";
import V1Structure from "../structure/v1Structure";
import { useAPI } from "../../connections/useAPI";
import { useAuth } from "../../connections/authContext";


const Courses = () => {
    const [ courses, setCourses ] = useState([]);
    const { extras } = useAPI();
    const { token } = useAuth();
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        extras.courses(token).then(data => {
            setCourses(data);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    return(
        <V1Structure>
            <div className="block">
                <p className="box section-description">
                    Mantente siempre al día en lo último del sector.
                </p>
            </div>
            <div className="block">
                {loading ? <p className="ml-5">Cargando datos...</p> : (courses.length === 0) && <p className="ml-5">No hay cursos disponibles</p>}
                {courses.map(course => {
                    return(
                        <div key={course.extrasCoursesId} className={`block card`}>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="image" style={{width:"128px"}}>
                                        <img className="" src={course.picture} alt="Placeholder image" />
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <p className="title is-4">{course.title}</p>
                                        <p className="subtitle is-6">Inicia: {course.startDate}<br />Finaliza: {course.endDate}</p>
                                    </div>
                                </div>
                                <div className="content">
                                    {course.description}
                                </div>
                                <footer className="card-footer">
                                    <a href={course.link} target="_blank" className={`card-footer-item`}>{course.buttonName}</a>
                                </footer>
                            </div>
                        </div>
                    );
                })}
            </div>
        </V1Structure>
    );
};

export default Courses;
export const Icon = ({name}) => {
    let className = "material-symbols-rounded";
    let icon = name;
    if(name.includes("fa-")){
        className=name;
        icon = "";
    }
    
    return(
        <i className={className}>{icon && icon}</i>
    );
};
import "./profileResume.css";
import { Icon } from "../../icons";
import { InputControl, InputFileControl } from "../../inputControls";
import { Fragment, useEffect, useRef, useState } from "react";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../modals";
import { useAPI } from "../../../connections/useAPI";
import { useAuth } from "../../../connections/authContext";
import { NotificationError, NotificationSuccess } from "../../notifications/notifications";

export const ProfilePicture = ({width}) => {
    const pictureRef = useRef();

    function handleChange(e){
        pictureRef.current.click();
    }

    return(
        <div className="profile-picture" style={{width}}>
            <input type="file" ref={pictureRef} />
            <img src="./assets/img/erika01.png" alt="" />
            <button className="change-picture" onClick={handleChange}><Icon name="add_a_photo" /></button>
        </div>
    );
}

export const ProfileResume = () => {
    const [ modalActive, setModalActive ] = useState(false);
    const [ data, setData ] = useState({});
    const [ newData, setNewData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");
    const { picture,firstName,fathersName,mothersName,profession,link } = newData;
    const { profile } = useAPI();
    const { token } = useAuth();
    let positionsText = "Edita tu información personal";

    /*if(positions){
        positionsText = "";
        positions.forEach((position,positionIndex) => {
            if(positionIndex < (positions.length - 1))
                positionsText += `, ${position}`;
            else
                positionsText += ` y ${position}`;
        });
        positionsText = (positionsText.substring(2));
    }*/

    useEffect(() => {
        setLoading(true);

        profile.get(token).then((response) => {
            if(response.length > 0){
                setData(response[0]);
                setNewData(response[0]);
            }

        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function toggleModal(){
        const active = !modalActive;
        setModalActive(active);
    }

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");

        if(data.uid){
            let f1 = null;
            if(firstName !== data.firstName) f1 = firstName;
            let f2 = null;
            if(fathersName !== data.fathersName) f2 = fathersName;
            let f3 = null;
            if(mothersName !== data.mothersName) f3 = mothersName;
            let f4 = null;
            if(profession !== data.profession) f4 = profession;
            let f5 = null;
            if(link !== data.link) f5 = link;
            let f6 = null;
            if(picture !== data.picture) f6 = picture;

            profile.edit(token,f1,f2,f3,f4,f5,f6).then((result) => {
                if(result.error){
                    setError(result.error.message);
                }else{
                    setData(result[0]);
                    toggleModal();
                    setSuccess("Los datos se han modificado correctamente");
                }
            }).finally(() => setLoading(false));
        }else{
            let f6 = null;
            if(picture !== data.picture) f6 = picture;

            profile.create(token,firstName,fathersName,mothersName,profession,link,f6).then((result) => {
                if(result.error){
                    setError(result.error.message);
                }else{
                    setData(result[0]);
                    toggleModal();
                    setSuccess("Los datos se han agregado correctamente");
                }
            }).finally(() => setLoading(false));
        }
    }

    return(
        <Fragment>
            <div className="box">
                { loading ? <strong>Cargando Información de Perfil...</strong> :
                    <article className="media">
                        <figure className="media-left">
                            <div className="profile-picture">
                                <img src={data.picture} />
                            </div>
                        </figure>
                        <div className="media-content">
                            <p className="title is-4">{data.firstName} {data.fathersName} {data.mothersName}</p>
                            <p className="subtitle is-6">{data.profession ? data.profession : positionsText}</p>
                            <p className="subtitle is-6">@{data.link}</p>
                        </div>
                        <div className="media-right">
                            <button className="button is-white" onClick={toggleModal}><Icon name="edit" /></button>
                        </div>
                    </article>
                }
                <p className="mt-6 has-text-centered">
                    {data.link &&
                        <Fragment>
                            Mira tu WellTalent Card<br />
                            <a href={`https://wellt.cards/${data.link}`} target="_blank">https://wellt.cards/{link}</a>
                        </Fragment>
                    }
                </p>
            </div>
            <ProfileResumeModal active={modalActive} toggle={toggleModal} value={newData} setValue={setNewData} onSubmit={handleSubmit} loading={loading} />
            {error && <NotificationError message={error} visibilityDuration={3000} />}
            {success && <NotificationSuccess message={success} visibilityDuration={3000} />}
        </Fragment>
    );
};

const ProfileResumeModal = ({active,toggle,value,setValue,onSubmit,loading}) => {
    const formRef = useRef();

    function handleChange(e){
        const currentField = e.currentTarget.getAttribute("field");
        const stringifiedValue = JSON.stringify(value);
        let auxiliarValue = JSON.parse(stringifiedValue);

        if(currentField !== "picture"){
            const currentValue = (e.currentTarget.value);
            auxiliarValue[currentField] = currentValue;
            auxiliarValue.picture = value.picture;
        }else
            auxiliarValue[currentField] = e.currentTarget.files[0];

        setValue(auxiliarValue);
    }

    function handleSubmit(e){
        e.preventDefault();

        const form = formRef.current;
        form.reportValidity();
        if(form.checkValidity())
            onSubmit(e);
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Resumen del Perfil">
            <ModalCardContent>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="block">
                        <InputFileControl leftIcon="photo_camera" type="file" field="picture" label="Foto de Perfil" onChange={handleChange} required={value.picture ? false : true} />
                    </div>
                    <div className="block">
                        <InputControl leftIcon="person" type="text" placeholder="Nombre/s" label="Nombre/s" value={value.firstName ? value.firstName : ''} field="firstName" onChange={handleChange} required />
                    </div>
                    <div className="block">
                        <InputControl leftIcon="face_6" type="text" placeholder="Primer Apellido" label="Primer Apellido" value={value.fathersName ? value.fathersName : ''} field="fathersName" onChange={handleChange} required />
                    </div>
                    <div className="block">
                        <InputControl leftIcon="face_4" type="text" placeholder="Segundo Apellido" label="Segundo Apellido" value={value.mothersName ? value.mothersName : ''} field="mothersName" onChange={handleChange} required />
                    </div>
                    <div className="block">
                        <InputControl leftIcon="person_book" type="text" placeholder="Profesión" label="Tu Profesión" value={value.profession ? value.profession : ''} field="profession" onChange={handleChange} required />
                    </div>
                    <div className="block">
                        <InputControl leftIcon="link" type="text" placeholder="Será la URL de tu Card (https://welltalent.cards/usuario)" label="Nombre de usuario" value={value.link ? value.link : ''} field="link" onChange={handleChange} required />
                    </div>
                </form>
            </ModalCardContent>
            <ModalCardFooter>
                <button type="submit" onClick={handleSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                <button type="reset" onClick={toggle} className="button is-secondary">Cancelar</button>
            </ModalCardFooter>
        </ModalCard>
    );
};
import { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../../connections/authContext";
import { useAPI } from "../../../connections/useAPI";
import { Icon } from "../../icons";
import { InputControl, InputSwitch, SelectControl, TextAndLevelInput } from "../../inputControls";
import { ModalCard, ModalCardContent, ModalCardFooter } from "../../modals";

export const PersonalData = () => {
    const [ data, setData ] = useState([]);
    const [ temporalData, setTemporalData ] = useState([]);
    const [ modalActive, setModalActive ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ sendLoading, setSendLoading ] = useState(false);
    const { resume } = useAPI();
    const { token } = useAuth();

    useEffect(() => {
        setLoading(true);

        resume.getAnswers(token).then((response) => {
            answerFormat(response);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function answerFormat(response){
        const auxiliarArray = [];
        response.forEach((question) => {
            auxiliarArray.push({
                questionId: question.id,
                question: question.question,
                placeholder: question.placeholder,
                options: question.options,
                type: question.type,
                status: question.status,
                userId: question.userId,
                answer: question.answer
            });
        });

        setData(auxiliarArray);
        setTemporalData(auxiliarArray);
    }

    function toggleModal(){
        const active = !modalActive;
        setModalActive(active);
    }

    function onSubmit(e){
        e.preventDefault();

        //setSendLoading(true);
        const dataToSend = temporalData.map((data) => {
            return({
                questionId: data.questionId,
                answer: data.answer
            });
        });

        resume.answer(token, JSON.stringify(dataToSend)).then((response) => {
            answerFormat(response);
            toggleModal();
        }).finally(() => {
            setSendLoading(false);
        });
    }

    return(
        <Fragment>
            <div className="box">
                <h2 className="subtitle" style={{color:"#623F9B",fontWeight:"bold"}}>Resumen</h2>
                {loading ? <p>Cargando Datos</p> :
                <article className="media">
                    <div className="media-content">
                        <table className="table is-fullwidth">
                            <tbody>
                                {data.map(item => {
                                    let answer = item.answer;
                                    const levels = ["Principiante","Intermedio","Avanzado","Experto","Nativo"];
                                    if(item.questionId === 11){
                                        answer = <ul>{item.answer.map((language) => {
                                            return <li key={language.language}>{language.language} ({levels[language.level]})</li>;
                                        })}</ul>;
                                    }
                                    return(
                                        <Fragment key={item.questionId}>
                                            <tr>
                                                <th className="">{item.question}</th>
                                            </tr>
                                            {item.answer && <tr><td>{answer}</td></tr>}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="media-right">
                        <button className="button is-white" onClick={toggleModal}><Icon name="edit" /></button>
                    </div>
                </article>}
            </div>
            {!loading && <ResumeModal
                active={modalActive}
                toggle={toggleModal}
                value={temporalData}
                setValue={setTemporalData}
                onSubmit={onSubmit}
                loading={sendLoading}
            />}
        </Fragment>
    );
};

const ResumeModal = ({active,toggle,value,setValue,onSubmit,loading}) => {
    function handleChange(e){
        let currentValue = e.currentTarget.value;
        const currentField = e.currentTarget.name;
        const allFields = JSON.parse(JSON.stringify(value));

        allFields[currentField].answer = currentValue;

        setValue(allFields);
    }

    return(
        <ModalCard active={active} toggle={toggle} title="Resumen">
                <ModalCardContent>
                    <form onSubmit={onSubmit}>
                        {value && value.map((item, itemIndex) => {
                            let component = null;

                            switch(item.type){
                                case "select":
                                    component = <SelectControl
                                        key={item.questionId}
                                        label={item.question}
                                        placeholder={item.placeholder}
                                        name={itemIndex}
                                        options={item.options}
                                        value={item.answer}
                                        onChange={handleChange}
                                    />
                                    break;
                                case "switch":
                                    component = <InputSwitch
                                        key={item.questionId}
                                        name={itemIndex}
                                        label={item.question}
                                        leftText={item.options.leftText}
                                        rightText={item.options.rightText}
                                        value={item.answer ? item.answer : "No"}
                                        onChange={handleChange}
                                    />;
                                    break;
                                case "languages":
                                    component = <LanguagesInputs
                                        key={item.questionId}
                                        name={itemIndex}
                                        values={item.answer ? item.answer : []}
                                        onChange={handleChange}
                                    />
                                    break;
                                default:
                                    component = <InputControl
                                        key={item.questionId}
                                        name={itemIndex}
                                        type={item.type}
                                        label={item.question}
                                        placeholder={item.placeholder}
                                        value={item.answer}
                                        onChange={handleChange}
                                    />
                            }

                            return component;
                        })}
                    </form>
                    
                </ModalCardContent>
                <ModalCardFooter>
                    <button type="submit" onClick={onSubmit} className="button is-primary" disabled={loading}>Guardar Cambios</button>
                    <button type="reset" onClick={toggle} className="button is-secondary">Cancelar</button>
                </ModalCardFooter>
        </ModalCard>
    );
};

const LanguagesInputs = ({ values, name, onChange }) => {
    const [ inputs, setInputs ] = useState([{language:"",level:0}]);

    useEffect(() => {
        if(values.length > 0) setInputs(values);
    },[]);

    function handleAddClick(e){
        e.preventDefault();

        const currentInputs = JSON.parse(JSON.stringify(inputs));
        currentInputs.push({language:'',level:0});

        setInputs(currentInputs);
    }

    return(
        <div>
            <div>
                {inputs.map((item, itemIndex) => {
                    const internalKey = `li-${itemIndex}`;

                    function setValue(value){
                        const newInputs = JSON.parse(JSON.stringify(inputs));
                        newInputs[itemIndex] = {language:value.text,level:value.level};
                        setInputs(newInputs);
                        onChange({currentTarget:{
                            value: newInputs,
                            name
                        }});
                    }

                    return(<TextAndLevelInput
                        key={internalKey}
                        label="Idioma"
                        placeholder="¿Qué idioma hablas?"
                        options={["Principiante","Intermedio","Avanzado","Experto","Nativo"]}
                        value={{text:item.language  ,level:item.level}}
                        setValue={setValue}
                    />);
                })}
            </div>
            <button className="button is-info" onClick={handleAddClick}><Icon name="add" /> Agregar Idioma</button>
        </div>
    );
};
import Structure from "../structure/structure";

const Recomendations = () => {
    return(
        <Structure>
            <section id="recomendations" className="card-page">
                <h1 className="title">Recomendaciones</h1>
                <p>Dile a tus contactos que nos envíen una recomendación tuya en la sección de <strong>Recomendaciones</strong> de tu <strong>WellTalent Card</strong></p>
                {/*<div className="recomendations-box">

                    <div className="recomendation">
                        <p>
                            Agueda Academia de Spa reconoce que Erika Palomera ha sido una excelente alumna y
                            adquirió todos los conocimientos y metodología necesaria para su desempeño como
                            profesionista en el ámbito de la cosmetología y el mundo spa. Estamos seguros de que
                            tendrá éxito y podrá ejercer con excelencia esta gran profesión.
                        </p>
                        <div className="person">
                            <img alt="" src="" />
                            <div>
                                <h4>Agueda Avalos</h4>
                                <h5>Directora Agueda Academia de Spa</h5>
                            </div>
                        </div>
                    </div>

                    <div className="recomendation">
                        <p>
                            No cabe duda que la dedicación se ve reflejada  en Erika, como compañera de
                            formación puedo decir que la calidad de sus servicios es excelente. Estarán en buenas
                            manos si deciden ir con ella.
                        </p>
                        <div className="person">
                            <img alt="" src="" />
                            <div>
                                <h4>Lucía Gonzalez</h4>
                                <h5>Terapeuta Spa Certificada</h5>
                            </div>
                        </div>
                    </div>

                    <div className="recomendation">
                        <p>
                            Excelentes servicios, sin duda el masaje relajante es uno de mis favoritos, lo recomiendo
                            totalmente. Te sientes en manos expertas, muy bien tratado y sales como nuevo. Sin
                            duda repetiré.
                        </p>
                        <div className="person">
                            <img alt="" src="" />
                            <div>
                                <h4>Mario E.</h4>
                                <h5>Paciente</h5>
                            </div>
                        </div>
                    </div>

                    <div className="recomendation">
                        <p>
                            Mi piel cada vez luce mejor. La limpieza facial me ha ayudado a mejorar el aspecto de
                            mi piel al punto de ya casi no utilizar maquillaje.
                        </p>
                        <div className="person">
                            <img alt="" src="" />
                            <div>
                                <h4>Alejandra Gutierrez</h4>
                                <h5>Paciente</h5>
                            </div>
                        </div>
                    </div>

                </div>*/}
            </section>
        </Structure>
    );
};

export default Recomendations;
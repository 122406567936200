/*import { useEffect, useState } from "react";

export const useFetch = (url, options = null) => {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);

    useEffect(() => {
        setLoading(true);

        fetch(url, options)
        .then(response => response.json())
        .then((data) => setData(data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }, []);

    return { data, loading, error };
};*/

export const useAPI = () => {
    const apiFunctions = {
        //currentServer: "http://localhost/iGitBackAPI/API's/wtcards",
        currentServer: "https://api.getwelltalent.com/",
        auth: {//Auth
            signin: async function (email, password){
                return await fetch(`${apiFunctions.currentServer}?app=user&function=signin`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `email=${email}&password=${password}`
                }).then(response => response.json());
            },
            signup: async function (email, password){
                return await fetch(`${apiFunctions.currentServer}?app=user&function=signup`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `email=${email}&password=${password}`
                }).then(response => response.json());
            },
            sendEmailToResetPassword: async function(email){
                const form = new FormData();
                form.append("email",email);

                return await fetch(`${apiFunctions.currentServer}?app=user&function=sendEmailToResetPassword`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            resetPassword: async function (token, password){
                const form = new FormData();
                form.append("token",token);
                form.append("password",password);

                return await fetch(`${apiFunctions.currentServer}?app=user&function=resetPassword`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            }
        },
        profile: {//Profile
            get: async function (token){
                const form = new FormData();
                form.append("token",token);

                return await fetch(`${apiFunctions.currentServer}?app=profile&function=get`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            create: async function (token,firstName,fathersName,mothersName,profession,link,picture){
                const form = new FormData();
                form.append("token",token);
                form.append("firstName",firstName);
                form.append("fathersName",fathersName);
                form.append("mothersName",mothersName);
                form.append("profession",profession);
                form.append("link",link);
                form.append("picture",picture);
                
                return await fetch(`${apiFunctions.currentServer}?app=profile&function=create`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            edit: async function (token,firstName,fathersName,mothersName,profession,link,picture){
                const form = new FormData();
                form.append("token",token);

                if(firstName) form.append("firstName",firstName);
                if(fathersName) form.append("fathersName",fathersName);
                if(mothersName) form.append("mothersName",mothersName);
                if(profession) form.append("profession",profession);
                if(link) form.append("link",link);
                if(picture) form.append("picture",picture);

                return await fetch(`${apiFunctions.currentServer}?app=profile&function=edit`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
        },
        socialLinks: {
            get: async function (token){
                const form = new FormData();
                form.append("token",token);

                return await fetch(`${apiFunctions.currentServer}?app=socialLinks&function=get`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            add: async function(token, facebook, instagram, linkedIn){
                const form = new FormData();
                form.append("token",token);
                if(facebook)
                    form.append("facebook",facebook);
                if(instagram)
                    form.append("instagram",instagram);
                if(linkedIn)
                    form.append("linkedin",linkedIn);

                return await fetch(`${apiFunctions.currentServer}?app=socialLinks&function=add`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            edit: async function(token, id, facebook, instagram, linkedIn){
                const form = new FormData();
                form.append("token",token);
                form.append("id",id);
                if(facebook)
                    form.append("facebook",facebook);
                if(instagram)
                    form.append("instagram",instagram);
                if(linkedIn)
                    form.append("linkedin",linkedIn);

                return await fetch(`${apiFunctions.currentServer}?app=socialLinks&function=edit`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            }
        },
        resume: {//Resume
            getQuestions: async function (token){
                const form = new FormData();
                form.append("token",token);

                return await fetch(`${apiFunctions.currentServer}?app=resume&function=getQuestions`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            getAnswers: async function (token){
                const form = new FormData();
                form.append("token",token);
                
                return await fetch(`${apiFunctions.currentServer}?app=resume&function=getAnswers`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            answer: async function (token, answer){
                const form = new FormData();
                form.append("token",token);
                form.append("answers",answer);

                return await fetch(`${apiFunctions.currentServer}?app=resume&function=answer`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
        },
        workExperience: {//WorkExperience
            get: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=workExperience&function=get`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            add: async function (token, company, startDate, endDate, position, description, file){
                const form = new FormData();
                form.append("token",token);
                form.append("company",company);
                form.append("startDate",startDate);
                form.append("endDate",endDate);
                form.append("position",position);
                form.append("description",description);
                form.append("recomendation",file);

                return await fetch(`${apiFunctions.currentServer}?app=workExperience&function=add`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            delete: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=workExperience&function=delete`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&id=${id}`
                }).then(response => response.json());
            },
        },
        jobTraining: {//JobTraining
            get: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=jobTraining&function=get`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            add: async function (token, school, startDate, endDate, description, file){
                const form = new FormData();
                form.append("token",token);
                form.append("school",school);
                form.append("startDate",startDate);
                form.append("endDate",endDate);
                form.append("description",description);
                form.append("certificate",file);

                return await fetch(`${apiFunctions.currentServer}?app=jobTraining&function=add`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            delete: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=jobTraining&function=delete`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&id=${id}`
                }).then(response => response.json());
            },
        },
        portfolio: {//Portfolio
            get: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=portfolio&function=get`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            add: async function (token, text, video){
                return await fetch(`${apiFunctions.currentServer}?app=portfolio&function=add`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&text=${text}&video=${video}`
                }).then(response => response.json());
            },
            edit: async function (token, id, text, video){
                return await fetch(`${apiFunctions.currentServer}?app=portfolio&function=edit`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&id=${id}&text=${text}&video=${video}`
                }).then(response => response.json());
            },
        },
        contactMail: {//Contact Mail
            get: async function (token){
                const form = new FormData();
                form.append("token",token);

                return await fetch(`${apiFunctions.currentServer}?app=contactMail&function=get`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            add: async function(token, email){
                const form = new FormData();
                form.append("token",token);
                form.append("email",email);

                return await fetch(`${apiFunctions.currentServer}?app=contactMail&function=add`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            edit: async function(token, id, email){
                const form = new FormData();
                form.append("token",token);
                form.append("id",id);
                form.append("email",email);

                return await fetch(`${apiFunctions.currentServer}?app=contactMail&function=edit`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            }
        },
        comments: {//Social Network
            get: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=comments&function=get`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            getReplies: async function(token, parentId){
                const form = new FormData();
                form.append("token",token);
                form.append("parentId",parentId);

                return await fetch(`${apiFunctions.currentServer}?app=comments&function=getReplies`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            add: async function (token, comment, picture, parentId = false){
                const form = new FormData();
                form.append("token",token);
                form.append("comment",comment);
                form.append("picture",picture);
                if(parentId) form.append("parentId",parentId);

                return await fetch(`${apiFunctions.currentServer}?app=comments&function=add`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            remove: async function (token, id){
                const form = new FormData();
                form.append("token", token);
                form.append("commentId", id);

                return await fetch(`${apiFunctions.currentServer}?app=comments&function=remove`,{
                    method: "post",
                    body: form
                }).then(response => response.json());
            },
            addLike: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=comments&function=addLike`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&commentId=${id}`
                }).then(response => response.json());
            },
            removeLike: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=comments&function=removeLike`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&commentId=${id}`
                }).then(response => response.json());
            },
            addSave: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=comments&function=addSave`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&commentId=${id}`
                }).then(response => response.json());
            },
            removeSave: async function (token, id){
                return await fetch(`${apiFunctions.currentServer}?app=comments&function=removeSave`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}&commentId=${id}`
                }).then(response => response.json());
            },
        },
        extras: {//Extras: Courses, Jobs and Events
            courses: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=extras&function=courses`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            jobs: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=extras&function=jobs`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            },
            events: async function (token){
                return await fetch(`${apiFunctions.currentServer}?app=extras&function=events`,{
                    method: "post",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `token=${token}`
                }).then(response => response.json());
            }
        }
    };
    
    return apiFunctions;
}
import PhantomStructure from "../structure/phantom";

const Account = () => {
    return(
        <PhantomStructure>
            Cuenta
        </PhantomStructure>
    );
};

export default Account;
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoImage from "../../images/logo.svg";

const PhantomStructure = ({children}) => {
    const [ menuVisible, setMenuVisible ] = useState('');

    function showMenu(e){
        e.preventDefault();

        setMenuVisible('visible');
    }

    function hideMenu(e){
        e.preventDefault();
        
        setMenuVisible('');
    }

    return(
        <div id="wrapper">
            <header id="header">
                <div className="inner">
                        <Link to="/" className="logo">
                            <span className="symbol"><img src={logoImage} alt="" /></span><span className="title">WellTalent Cards</span>
                        </Link>

                        <nav>
                            <ul>
                                <li><a href="#menu" onClick={showMenu}>Menú</a></li>
                            </ul>
                        </nav>

                </div>
            </header>

            <nav id="menu" className={menuVisible}>
                <div className="inner">
                    <h2>Menu</h2>
                    <ul>
                        <li><NavLink to="/">Card</NavLink></li>
                        <li><NavLink to="/account">Cuenta</NavLink></li>

                        <li><NavLink to="/courses">Formación</NavLink></li>
                        <li><NavLink to="/jobs">Empleos</NavLink></li>
                        <li><NavLink to="/events">Eventos</NavLink></li>
                        <li><NavLink to="/tips">Tips</NavLink></li>

                        <li><a href="#logout">Cerrar Sesión</a></li>
                    </ul>
                </div>
                <a class="close" href="#menu" onClick={hideMenu}>Close</a>
            </nav>

            <div id="main">
                <div className="inner">
                    {children}
                </div>
            </div>

        
            {/*<footer id="footer">
                <div className="inner">
                    <section>
                        <h2>Get in touch</h2>
                        <form method="post" action="#">
                            <div className="fields">
                                <div className="field half">
                                    <input type="text" name="name" id="name" placeholder="Name" />
                                </div>
                                <div className="field half">
                                    <input type="email" name="email" id="email" placeholder="Email" />
                                </div>
                                <div className="field">
                                    <textarea name="message" id="message" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <ul className="actions">
                                <li><input type="submit" value="Send" className="primary" /></li>
                            </ul>
                        </form>
                    </section>
                    <section>
                        <h2>Follow</h2>
                        <ul className="icons">
                            <li><a href="#" className="icon brands style2 fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href="#" className="icon brands style2 fa-facebook-f"><span className="label">Facebook</span></a></li>
                            <li><a href="#" className="icon brands style2 fa-instagram"><span className="label">Instagram</span></a></li>
                            <li><a href="#" className="icon brands style2 fa-dribbble"><span className="label">Dribbble</span></a></li>
                            <li><a href="#" className="icon brands style2 fa-github"><span className="label">GitHub</span></a></li>
                            <li><a href="#" className="icon brands style2 fa-500px"><span className="label">500px</span></a></li>
                            <li><a href="#" className="icon solid style2 fa-phone"><span className="label">Phone</span></a></li>
                            <li><a href="#" className="icon solid style2 fa-envelope"><span className="label">Email</span></a></li>
                        </ul>
                    </section>
                    <ul className="copyright">
                        <li>&copy; Untitled. All rights reserved</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                    </ul>
                </div>
            </footer>*/}


        </div>
    );
};

export default PhantomStructure;
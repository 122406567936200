import "./access.css";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputControl } from "../../components/inputControls";
import { useAuth } from "../../connections/authContext";
import { useAPI } from "../../connections/useAPI";

const ForgotPassword = () => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const emailRef = useRef();
    const { token } = useAuth();
    const { auth } = useAPI();
    const navigate = useNavigate();

    useEffect(() => {
        if(!loading)
            if(!error)
                if(token)
                    navigate("/");
    },[loading]);

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        setError("");

        auth.sendEmailToResetPassword(emailRef.current.value).then((data) => {
            //console.log(data);
        }).finally(() => {
            setError("Si tu correo se encuentra en nuestra base de datos, te llegará un correo elecrónico con un enlace para poder restablecer tu contraseña.");
            setLoading(false);
        });
    }

    return(
        <div className="hero is-fullheight is-primary access-background" style={{"marginTop":"-3.25rem"}}>
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="column is-8 is-offset-2">
                        <h3 className="title">Olvidé mi contraseña</h3>
                        <hr className="login-hr" />
                        <p className="subtitle">Te enviaremos un correo electrónico para restablecer tu contraseña</p>

                        <div className="box">
                            <div className="">
                                <img src="./assets/img/logo-dark.png" style={{"width":"200px"}} alt="WellTalent Cards Logo" />
                            </div>
                            { error && <div className="notification is-success">{error}</div>}
                            <form onSubmit={handleSubmit}>
                                <InputControl ref={emailRef} leftIcon="email" type="email" placeholder="Correo Electrónico" autoFocus required />

                                <button disabled={loading} className="button is-block is-primary is-large is-fullwidth">Enviar</button>
                            </form>
                        </div>
                    </div>

                    <p className="has-text-white">
                        <Link to="/login">Ya recordé mi contraseña</Link>
                    </p>
                    <hr />
                    <p className="has-text-white">
                        <Link to="/policy">Políticas de Privacidad</Link> &nbsp;·&nbsp;
                        <Link to="/terms">Términos y Condiciones</Link>
                    </p>
                    
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
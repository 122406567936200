import React, { useContext, useEffect, useState } from "react";
import { useAPI } from "./useAPI";

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [ token, setToken ] = useState();
    const [ loading, setLoading ] = useState(true);
    const { auth } = useAPI();

    useEffect(() => {
        const savedToken = localStorage.getItem("sessionToken");

        if(savedToken)
            setToken(savedToken);
        
        setLoading(false);
    }, []);

    function register(email, password, setError, setLoadingRegister){
        auth.signup(email, password)
        .then((data) => {
            if(data.error)
                setError(data.error.message);
            else{
                localStorage.setItem("sessionToken", data);
                setToken(data);
            }
        }).catch((error) => {
            setError(error)
        }).finally(() => {
            setLoadingRegister(false);
        });
    }

    function login(email, password, setError, setLoadingLogin){
        auth.signin(email,password)
        .then((data) => {
            if(data.error)
                setError(data.error.message);
            else{
                localStorage.setItem("sessionToken",data.sessionToken);
                setToken(data.sessionToken);
            }
        }).catch((error) => {
            setError(error);
        }).finally(() => {
            setLoadingLogin(false);
        });
    }

    function logout(){
        localStorage.removeItem("sessionToken");
        setToken(null);
    }

    const value = {
        token,
        register,
        login,
        logout
    };

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};


import "./notifications.css";
import { useState } from "react";

export const Notification = ({type, color, intensity, deleteButton, message, visibilityDuration}) => {
    const [ visible, setVisible ] = useState(true);
    let classBulder = "notification";
    if(type) classBulder += ` ${type}`;
    if(color) classBulder += ` is-${color}`;
    if(intensity) classBulder += ` is-${intensity}`;

    if(visibilityDuration){
        setTimeout(() => {
            setVisible(false);
        }, visibilityDuration);
    }

    return visible ? (
        <div className={classBulder}>
            {deleteButton && <button className="delete"></button>}
            {message}
        </div>
    ) : null;
};

export const NotificationSuccess = ({message,visibilityDuration}) => {
    let duration = visibilityDuration;
    duration ??= 3000;

    return <Notification
        type="toast"
        color="success"
        message={message}
        visibilityDuration={duration}
    />;
};

export const NotificationError = ({message,visibilityDuration}) => {
    let duration = visibilityDuration;
    duration ??= 3000;

    return <Notification
        type="toast"
        color="danger"
        //intensity="light"
        message={message}
        visibilityDuration={duration}
    />;
};
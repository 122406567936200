import V1Structure from "../structure/v1Structure";

const Messages = () => {
    return(
        <V1Structure>
            <h1 className="title mt-5">Mensajes</h1>

            <div className="box">
                <strong>Nombre del Contactante</strong>
                <p>Mensaje</p>
            </div>
        </V1Structure>
    );
};
export default Messages;